<template>
  <div class="particu">
    <div class="particu_name">
      <div>
        <div class="title">{{ pageData.goodsName }}</div>
        <div class="number">
          商品编号：<span>{{ pageData.goodsNum }}</span>
        </div>
      </div>

      <div>
        <div style="text-align: left">
          <div style="font-size: 20px; font-weight: bold">
            {{ pageData.enterpriseName }}
          </div>
          <div>
            <i class="hy-icon-dianhua"></i>
            {{ pageData.contactInfo }}
          </div>
        </div>
      </div>
    </div>
    <!-- 竞价 -->
    <div class="particu_cont" v-if="pageData">
      <div class="particu_items">
        <div class="item_images" v-if="pageData">
          <span v-if="pageData.taxIncluded == 0">含税</span
          ><span v-if="pageData.taxIncluded == 1">不含税</span>
          <span v-if="pageData.priceType == 0">未知</span>
          <span v-if="pageData.priceType == 1">坑口价</span>
          <span v-if="pageData.priceType == 2">出厂价</span>
          <span v-if="pageData.priceType == 3">船板价</span>
          <span v-if="pageData.priceType == 4">平仓价</span>
          <span v-if="pageData.priceType == 5">车板价</span>
          <span v-if="pageData.priceType == 6">到厂价</span>
        </div>
        <div
          v-if="pageData.listingPrice"
          style="margin-left: 20px; margin-top: 5px"
        >
          <span style="color: rgb(208, 17, 26)"><span style="color: #212121;">价格:</span></span
          ><span style="color: rgb(208, 17, 26); font-size: 30px">￥{{
            pageData.listingPrice
          }}</span
          >元/吨
        </div>
        <div
          v-if="pageData.bond && this.$route.path !== '/piecingDetails'"
          style="margin-left: 100px"
        >
          保证金:<span
            style="color: rgb(208, 17, 26); marin-left: 100px; font-size: 30px"
            >{{ pageData.bond }}万元</span
          >
        </div>

        <div v-if="pageData.stockCount" style="margin-left: 100px">
          <span v-if="this.$route.path == '/piecingDetails'">订单数量：</span>
          <span style="color: rgb(208, 17, 26); font-size: 30px"
            >{{ pageData.stockCount }}吨
          </span>
        </div>
        <div v-if="pageData.orderCount" style="margin-left: 100px">
          下单数量:
          <span style="color: rgb(208, 17, 26); font-size: 30px">{{
            pageData.orderCount
          }}</span
          >吨
        </div>
      </div>
    </div>

    <div class="auction">
      <h3>发布方</h3>
      <div class="auction_item" v-if="pageData.payType">
        <div v-if="pageData.payType">
          <span style="display: inline-block; width: 100px">支付方式：</span>
          <span>{{ pageData.payType | initPayType }}</span>
        </div>
      </div>
      <div class="auction_item" v-if="pageData.deliveryMode">
        <div v-if="pageData.deliveryMode">
          <span style="display: inline-block; width: 100px">配送方式：</span>
          <span>{{
            pageData.deliveryMode == 1
              ? "送货"
              : pageData.deliveryMode == 2
              ? "自提"
              : ""
          }}</span>
        </div>
      </div>

      <div class="auction_item" v-if="tradeGoodsListing">
        <div
          v-if="
            tradeGoodsListing.ifSplit != null &&
            this.$route.path !== '/piecingDetails'
          "
        >
          <span style="display: inline-block; width: 100px">是否可拆分：</span>
          <span>{{
            tradeGoodsListing.ifSplit == 0 ? "标的物可拆分" : "标的物不可拆分"
          }}</span>
        </div>
      </div>
      <div
        class="auction_item"
        v-if="pageData && this.$route.path !== '/piecingDetails'"
      >
        <div>
          <span style="display: inline-block; width: 100px">竞价日期：</span>
          <span>{{ pageData.startTime }} ~ {{ pageData.endTime }}</span>
        </div>
      </div>
    </div>
    <div class="relation">
      <span style="display: inline-block; width: 100px">联系方式：</span>
      <span>{{ pageData.contacts }}</span>
      <span style="margin-left: 20px; width: 100px">{{
        pageData.contactInfo
      }}</span>
    </div>
    <div class="remark">
      <div style="width: 100px">交割地：</div>
      <div>{{ pageData.deliveryPlace }}</div>
    </div>
    <div class="remark">
      <div style="width: 100px">产地：</div>
      <div>{{ pageData.originPlace }}</div>
    </div>

    <div
      style="
        margin-top: 20px;
        border-bottom: 1px dashed #ccc;
        padding-bottom: 10px;
      "
      v-if="pageData.feedCompanyName && ifInvite != 1"
    >
      <h3>参与方</h3>
      <div class="relation">
        <span style="display: inline-block; width: 100px">单位名称：</span>
        <span>{{ pageData.feedCompanyName || "" }}</span>
      </div>
      <div class="relation">
        <span style="display: inline-block; width: 100px">联系方式：</span>
        <span>{{ pageData.feedPhone || "" }}</span>
      </div>
    </div>

    <div class="indicator">
      <div style="width: 100px">商品指标：</div>
      <div class="tables">
        <table border="1">
          <tr class="tables_tr">
            <td v-for="(item, index) in quota" :key="index">{{ item.name }}</td>
          </tr>
          <tr class="tables_tr">
            <td v-for="(item, index) in quota" :key="index">
              {{ item.value }}
            </td>
          </tr>
        </table>
      </div>
    </div>

    <div class="remark">
      <div style="width: 100px">备注说明：</div>
      <div>{{ pageData.remark }}</div>
    </div>
  </div>
</template>

<script>
import { groupDtetails, orderDetail } from "@/api/public.js";
export default {
  data() {
    return {
      pageData: {},
      tradeGoodsListing: {},
      quota: [],
      pageData: null,
      pageData: {},
      selectOrderType: null,
      ifInvite: null,
    };
  },
  components: {
    // CountDown: () => import("@/components/countDowns.vue"),
  },
  filters: {
    listingPrice(data) {
      if (data || data == 0) {
        return data == 0 ? "面议" : "￥" + data + "/吨";
      } else {
        return "";
      }
    },
    initPayType(val) {
      let arr = ["未知", "现金", "承兑", "现金+承兑"];
      return arr[val];
    },
  },
  mounted() {
    this.selectOrderType = this.$route.query.selectOrderType;
    this.ifInvite = this.$route.query.ifInvite;
    console.log(this.$route.query.ifInvite, "哈哈哈哈哈哈哈哈哈哈哈");
    if (this.$route.query.goodsId) {
      this.getlisst(this.$route.query.goodsId);
    } else if (this.$route.query.orderId) {
      this.orderList(this.$route.query.orderId);
    }
  },
  props: {},
  methods: {
    getlisst() {
      groupDtetails(this.$route.query.goodsId).then((res) => {
        if (res.code == 0) {
          this.pageData = res.data;
          console.log(res,"99999999999")
          if (res.data.quotaList) {
            let quotas = res.data.quotaList;
            quotas.forEach((item) => {
              item.name =
                item.name +
                `(${item.unit.split(";")[0]})` +
                `${item.unit.split(";")[2]}`;
              item.value = item.value + `${item.unit.split(";")[1]}`;
            });
            this.quota = quotas;
          }
          if (res.data.pageData) {
            let newlist = res.data.pageData;
            if (newlist.createTime) {
              newlist.createTime = newlist.createTime.replace("T", " ");
            }
            newlist.createTime = newlist.createTime.replace("T", " ");
            if (newlist.deliveryStartTime) {
              newlist.deliveryStartTime = newlist.deliveryStartTime.replace(
                "T",
                " "
              );
            }
            if (newlist.deliveryEndTime) {
              newlist.deliveryEndTime = newlist.deliveryEndTime.replace(
                "T",
                " "
              );
            }
            this.pageData = newlist;
          }
          if (res.data.pageData) {
            let list = res.data.pageData;
            for (const key in list) {
              if (key == "startTime") {
                list[key] = list[key].replace("T", " ");
              }
              if (key == "endTime") {
                list[key] = list[key].replace("T", " ");
              }
            }
            this.pageData = list;
          }
          this.$emit("child-event", res.data);
          if (res.data && res.data.tradeGoodsListing) {
            this.tradeGoodsListing = res.data.tradeGoodsListing;
          } else {
            this.tradeGoodsListing = null;
          }
          console.log(this.tradeGoodsListing, "this.tradeGoodsListing");
        }
      });
    },
    orderList(id) {
      console.log(id);
      let config = {
        orderId: id,
      };
      orderDetail(config).then((res) => {
        if (res.code == 0) {
          this.pageData = res.data;
          if (res.data.quotas) {
            let quotas = res.data.quotas;
            quotas.forEach((item) => {
              item.name =
                item.name +
                `(${item.unit.split(";")[0]})` +
                `${item.unit.split(";")[2]}`;
              item.value = item.value + `${item.unit.split(";")[1]}`;
            });
            this.quota = quotas;
          }
          if (res.data.pageData) {
            let newlist = res.data.pageData;
            if (newlist.createTime) {
              newlist.createTime = newlist.createTime.replace("T", " ");
            }
            newlist.createTime = newlist.createTime.replace("T", " ");
            if (newlist.deliveryStartTime) {
              newlist.deliveryStartTime = newlist.deliveryStartTime.replace(
                "T",
                " "
              );
            }
            if (newlist.deliveryEndTime) {
              newlist.deliveryEndTime = newlist.deliveryEndTime.replace(
                "T",
                " "
              );
            }
            this.pageData = newlist;
          }
          if (res.data.pageData) {
            let list = res.data.pageData;
            for (const key in list) {
              if (key == "startTime") {
                list[key] = list[key].replace("T", " ");
              }
              if (key == "endTime") {
                list[key] = list[key].replace("T", " ");
              }
            }
            this.pageData = list;
          }
          this.$emit("child-event", res.data);
          if (res.data && res.data.tradeGoodsListing) {
            this.tradeGoodsListing = res.data.tradeGoodsListing;
            console.log(this.tradeGoodsListing, "this.tradeGoodsListing");
          } else {
            this.tradeGoodsListing = null;
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.item_images {
  width: 100px;
  height: 24px;
  background: url("../../../assets/images/tag.png") no-repeat;
  background-size: 100px 100%;
  color: white;
  padding-left: 3px;
  padding-top: 2px;
  margin-top: 10px;
  display: flex;
}
.particu_cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px dashed #ccc;
  border-bottom: 1px dashed #ccc;
  margin-top: 20px;
}
.particu {
  padding: 20px;
  border: 1px solid #ccc;
  .particu_name {
    display: flex;
    justify-content: space-between;
    .title {
      font-size: 18px;
      font-weight: bold;
    }
    .number {
      margin-top: 5px;
      // margin-left: 20px;
    }
  }
  .particu_items {
    display: flex;
    align-content: center;
    // justify-content: space-between;
    // padding: 20px;
    padding: 20px 0;
    // border: 1px solid #ccc;
    // margin-top: 20px;
  }
  .particu_item {
    display: flex;
    // justify-content: space-between;
    // padding: 20px;
    padding: 20px 0;
    // border: 1px solid #ccc;
    border-top: 1px dashed #ccc;
    border-bottom: 1px dashed #ccc;
    margin-top: 20px;
  }
  .auction {
    margin-top: 20px;
    .auction_item {
      // display: flex;
      margin-top: 20px;
      .finish {
        // margin-left: 20px;
        margin-top: 20px;
      }
    }
  }
  .relation {
    margin-top: 20px;
  }
  .delivery {
    margin-top: 20px;
  }
  .indicator {
    margin-top: 20px;
    display: flex;
    .tables {
      // margin-top: 10px;
      text-align: center;
      table {
        border: none;
      }
      .tables_tr {
        // display: flex;
        flex-wrap: wrap;
        font-weight: bold;
      }
      td {
        border: none;
        width: 220px;
        padding: 5px 10px;
        border: 1px dashed black;
      }
    }
  }
  .remark {
    margin-top: 20px;
    display: flex;
  }
}
</style>
