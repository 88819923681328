import { render, staticRenderFns } from "./logisticsDetails.vue?vue&type=template&id=de3cb814&scoped=true&"
import script from "./logisticsDetails.vue?vue&type=script&lang=js&"
export * from "./logisticsDetails.vue?vue&type=script&lang=js&"
import style0 from "./logisticsDetails.vue?vue&type=style&index=0&id=de3cb814&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "de3cb814",
  null
  
)

export default component.exports