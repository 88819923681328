<template>
  <div>
    <div class="goodsdetail">
      <particulars ref="detail"></particulars>
      <div class="groupInformation">
        <div class="titleInfo">拼团信息</div>
        <div class="tableMain">
          <div class="pirceTable">
            <div class="gear">
              <div class="pirce">拼团价格挡位：</div>
              <div class="table">
                <el-table :data="pageData" border>
                  <el-table-column
                    prop="groupCount"
                    label="数量"
                    width="120"
                    align="center"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="groupPrice"
                    label="元/吨"
                    width="120"
                    align="center"
                  >
                  </el-table-column>
                </el-table>
              </div>
            </div>
            <div style="margin-top: 10px">
              拼团时间：{{ gruopData.groupStartTime | formatCreateTime }}开始-{{
                gruopData.groupEndTime | formatCreateTime
              }}结束
            </div>
          </div>

          <div class="border"></div>
          <div class="tableRight">
            <div>参与拼团信息：</div>
            <div class="table">
              <el-table border :data="TbaleList">
                <el-table-column
                  label="用户"
                  prop="enterpriseName"
                  align="center"
                  width="360"
                >
                  <template slot-scope="scope">
                    <div>
                      <el-tooltip
                        class="item"
                        effect="dark"
                        :content="scope.row.enterpriseName"
                        placement="top-start"
                      >
                        <span>
                          <span class="indicator_span">{{
                            scope.row.enterpriseName
                          }}</span></span
                        >
                      </el-tooltip>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="拼团数量"
                  prop="orderCount"
                  width="120"
                  align="center"
                >
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
      <div
        class="orderNumber"
        v-if="
          gruopData.goodsStatus !== 0 &&
          gruopData.goodsStatus !== 2 &&
          gruopData.goodsStatus !== 3 &&
          userId != gruopData.publisherId &&
          userId
        "
      >
        <div class="order">
          <el-form label-width="80px">
            <el-form-item
              label="下单数量:"
              prop="orderCount"
              style="white-space: nowrap"
            >
              <el-input style="width: 200px" v-model="num"></el-input>
            </el-form-item>
          </el-form>
          <el-button
            type="primary"
            class="join"
            @click="dialogVisible = true"
            :disabled="num == ''"
            >参加拼团</el-button
          >
          <el-button
            class="collect"
            icon="el-icon-plus"
            @click="follow"
            v-if="gruopData.ifCollect == 0"
            >收藏商品</el-button
          >
          <el-button
            class="collect"
            icon="el-icon-plus"
            @click="follows"
            v-if="gruopData.ifCollect == 1"
            >取消收藏</el-button
          >
        </div>
      </div>

      <div class="success" v-if="gruopData.groupStatus === 2">
        <div>拼团成功</div>
        <div>
          前往订单信息进行合同签订及履约,<span
            @click="goContract"
            style="color: blue; cursor: pointer"
            >点击前往</span
          >
        </div>
      </div>
      <div class="success" v-if="gruopData.groupStatus === 3">
        <div>拼团失败</div>
      </div>

      <el-dialog
        center
        title="拼团规则声明"
        :visible.sync="dialogVisible"
        width="30%"
        style="font-size: 18px; font-weight: 600"
      >
        <div>
          <div style="margin-left: 40px; font-size: 18px">
            <p>参与拼团需要支付预付款</p>
            <p>拼团时间截至若拼团成功，则需履约合同后退回账户余额</p>
            <p>拼团时间结束拼团失败，预付款将退回您的账户余额</p>
          </div>
          <div style="margin-top: 15px; margin-left: 70px">
            <el-table :data="tableData" style="width: 361px" border center>
              <el-table-column
                prop="payRatio"
                label="支付比例(%)"
                width="180"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="premium"
                label="单吨溢价(元/吨)"
                width="180"
                align="center"
              >
              </el-table-column>
            </el-table>
          </div>
        </div>

        <div
          slot="footer"
          class="dialog-footer"
          style="margin-top: 30px; margin-left: 10px; text-align: center"
        >
          <el-button @click="toPay">去支付</el-button>
          <el-button type="primary" @click="dialogVisible = false"
            >取消</el-button
          >
        </div>
      </el-dialog>

      <el-dialog
        center
        title="支付预约款"
        :visible.sync="dialogVisible1"
        width="30%"
      >
        <div style="margin-left: 45px">
          <span>请选择支付比例</span>
          <div style="margin-top: 15px">
            <el-table
              :data="tableData"
              style="width: 416px"
              border
              highlight-current-row
            >
              <el-table-column label="操作" width="55">
                <template slot-scope="scope">
                  <!-- 可以手动的修改label的值，从而控制选择哪一项 -->
                  <el-radio
                    class="radio"
                    @change="radioChange"
                    v-model="templateSelection"
                    :label="scope.row"
                    >&nbsp;</el-radio
                  >
                </template>
              </el-table-column>
              <el-table-column prop="payRatio" label="支付比例(%)" width="180">
              </el-table-column>
              <el-table-column
                prop="premium"
                label="单吨溢价(元/吨)"
                width="180"
              >
              </el-table-column>
            </el-table>
          </div>
          <div style="margin-top: 10px">下单数量：{{ num }}</div>
          <div style="margin-top: 15px">需支付金额：{{ allPice }}</div>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="openPayBond">确认支付</el-button>
        </div>
      </el-dialog>
    </div>
    <InputPasswordDialog
      :isShow="passWordIsShows"
      ref="InputPasswordDialog"
      title="请输入交易密码"
      @close="closeDialog('passWordIsShow')"
      @submit="submit"
    />
    <el-dialog
      title="请选择支付方式"
      width="30%"
      :visible.sync="dialogofflineForm"
    >
      <el-form :model="offlineForm" ref="offlineForm" :rules="offlineFormRule">
        <el-form-item label="支付方式:" prop="bankType">
          <el-radio
            v-for="(item, index) in balances"
            :key="index"
            v-model="offlineForm.bankType"
            :label="item.bankType"
            :disabled="item.ifAsh"
          >
            <span v-if="item.bankType == 0"
              >中信账户余额(￥{{ item.balance }})</span
            >
            <span v-if="item.bankType == 1"
              >中金账户余额(￥{{ item.balance }})</span
            >
          </el-radio>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogofflineForm = false">取 消</el-button>
        <el-button type="primary" @click="flineFormClicks()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import particulars from "./particulars.vue";
import { groupDtetails } from "@/api/public.js";
import { formatDate } from "@/utils/date";
import { reqIsPayBonds, reqFollow, joinGroup } from "@/api/hy";
import * as types from "@/store/action-types";
import md5 from "js-md5";
import Vue from "vue";
import InputPasswordDialog from "./passwordInput.vue";
import { getAccountBalanceByParam } from "@/api/Accountopening";
import floatCompute from "@/utils/floatCompute.js";
export default {
  components: {
    particulars,
    InputPasswordDialog,
  },
  name: "",
  data() {
    return {
      offlineForm: {
        bankType: "",
      },
      balances: [],
      passWordIsShows: false,
      dialogofflineForm: false,
      templateSelection: "",
      dialogVisible: false,
      dialogVisible1: false,
      pageData: [],
      num: "",
      TbaleList: [],
      gruopData: [],
      tableData: [],
      userId: "",
      allPice: 0,
      offlineFormRule: {
        bankType: [
          { required: true, message: "请选择支付方式", trigger: "change" },
        ],
      },
    };
  },
  mounted() {
    this.userId = Vue.ls.get("userId");
    this.getlisst();
  },
  filters: {
    formatCreateTime(time) {
      let date = new Date(time);
      return formatDate(date, "yyyy-MM-dd hh:mm:ss");
    },
  },
  methods: {
    //签合同
    goContract() {
      // this.$router.push({
      //   path: "'/order",
      // });
      this.$router.push({
        path: "/order",
      });
    },
    btnClick() {
      let config = {
        searchType: 3,
      };
      getAccountBalanceByParam(config).then((res) => {
        if (res.code == 0) {
          this.balances = res.data;
          this.dialogofflineForm = true;
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      });
    },
    flineFormClicks(e) {
      this.dialogofflineForm = false;
      let that = this;
      if (that.offlineForm.bankType == 0 || that.offlineForm.bankType == 1) {
        that.passWordIsShows = true;
      }
    },
    getreqIsPayBond() {
      this.btnClick();
    },
    submit(val) {
      console.log(val, "222222");
      let id = this.$route.query.goodsId;
      let config = {
        goodsId: id,
        groupPremiumId: this.templateSelection.groupPremiumId,
        payAmount: this.allPice,
        orderCount: this.num,
        tradePassword: md5(val),
        bankType: this.offlineForm.bankType,
      };
      joinGroup(config).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: res.data,
            type: "success",
          });
          this.$route.go(-1);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    closeDialog(tag) {
      this[tag] = false;
      this.passWordIsShows = false;
    },
    biddingOffer() {},
    openPayBond() {
      this.dialogVisible1 = false;
      let id = Vue.ls.get(types.enterpriseId);
      reqIsPayBonds(id).then((ret) => {
        console.log(ret);
        if (ret.code == 0) {
          console.log(ret.data);
          if (ret.data) {
            this.getreqIsPayBond();
          } else {
            this.$confirm("暂无设置交易密码, 是前往设置?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                this.$router.push("/finance");
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "已取消",
                });
              });
          }
        } else {
          this.$message({
            type: "error",
            message: ret.message,
          });
        }
      });
    },
    toPay() {
      this.dialogVisible = false;
      this.dialogVisible1 = true;
    },
    radioChange(e) {
      // let newNum =
      //   Number(this.num) *
      //   Number(this.gruopData.listingPrice) *
      //   (Number(this.templateSelection.payRatio) / 100);

      let a = floatCompute.floatDiv(
        Number(this.templateSelection.payRatio), 100
      );
      let b = floatCompute.floatMul(
        Number(this.num) , Number(this.gruopData.listingPrice)
      );
      let newNum = floatCompute.floatMul(b,a)
      this.allPice = newNum;
    },
    getlisst() {
      groupDtetails(this.$route.query.goodsId).then((res) => {
        if (res.code == 0) {
          console.log(res, "====================");
          this.tableData = res.data.groupPremiumList;
          this.pageData = res.data.groupPriceList;
          this.TbaleList = res.data.groupEnterpriseList;
          this.gruopData = res.data;
        }
      });
    },
    //收藏商品
    follow() {
      if (!this.beforhandle("personalCenter:auctionSale:partake:collect"))
        return;
      reqFollow({
        goodsId: this.$route.query.goodsId,
        type: 0,
      })
        .then((ret) => {
          if (ret.code == 0) {
            this.$message({
              type: "success",
              message: "操作成功",
            });
            // this.$refs.detail.getlisst(this.$route.query.goodsId);
            this.getlisst();
          } else {
            this.$message({
              type: "error",
              message: ret.message,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //取消收藏
    follows() {
      this.$confirm("是否要取消收藏?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          reqFollow({
            goodsId: this.$route.query.goodsId,
            type: 1,
          }).then((res) => {
            if (res.code == 0) {
              // this.$refs.detail.getlisst(this.$route.query.goodsId);
              this.getlisst();
              this.$message({
                type: "success",
                message: "取消收藏成功!",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
  },
};
</script>

<style lang='scss' scoped>
.goodsdetail {
  width: 1200px;
  padding: 25px 20px;
  background: white;
  border: 1px solid #e5e5e5;
  margin: 15px auto;

  &::before,
  &::after {
    display: table;
    content: "";
  }
  .groupInformation {
    margin-top: 10px;
    border: 1px solid #ccc;
    padding: 25px 20px;
  }

  .titleInfo {
    font-size: 18px;
    font-weight: bold;
  }
  .tableMain {
    display: flex;
    flex-direction: row;
    .pirceTable {
      margin-top: 20px;
      width: 500px;

      .gear {
        display: flex;
        .pirce {
          font-size: 14px;
        }
      }
    }
    .border {
      width: 2px;
      background-color: #6666;
    }
    .tableRight {
      margin-left: 30px;
      margin-top: 20px;
      display: flex;
    }
  }

  .orderNumber {
    margin-top: 10px;
    border: 1px solid #ccc;
    .order {
      display: flex;
      margin-top: 20px;
      padding-left: 20px;
      .join {
        margin-left: 10px;
        width: 150px;
        height: 50px;
        margin-top: -3px;
      }
      .collect {
        margin-left: 10px;
        width: 150px;
        height: 50px;
        margin-top: -3px;
      }
    }
  }
  .success {
    margin-top: 10px;
    border: 1px solid #ccc;
  }
}
</style>
