<template>
  <div>
    <div class="add-goods">
      <el-form ref="form" :model="formData" :rules="rules" label-width="140px">
        <div class="title">基本信息</div>
        <div class="group info-box">
          <div class="line">
            <el-form-item label="商品分类" prop="goodsType">
              <el-select
                v-model="formData.goodsType"
                @change="goodsChange"
                placeholder="请选择商品分类"
              >
                <el-option
                  v-for="item in nameList"
                  :key="item.id"
                  :label="item.dictName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="商品名称" prop="goodsName">
              <el-input
                v-model.trim="formData.goodsName"
                placeholder="请输入商品名称"
                maxlength="11"
                min="1"
              >
              </el-input>
            </el-form-item>
          </div>
          <div class="line">
            <div class="pirceType">
              <el-form-item label="价格类型" prop="taxIncluded">
                <el-select
                  v-model="formData.taxIncluded"
                  placeholder="是否含税"
                  @change="selectChange('taxIncluded')"
                >
                  <el-option label="含税" :value="0"></el-option>
                  <el-option label="不含税" :value="1"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="" prop="priceType" label-width="0">
                <el-select
                  v-model="formData.priceType"
                  placeholder="请选择类型"
                  @change="selectChange('priceType')"
                >
                  <el-option
                    v-for="item in priceTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <el-form-item
              label="单吨价格"
              prop="listingPrice"
              class="floorPrice"
            >
              <el-input
                v-model="formData.listingPrice"
                placeholder="请输入单吨价格"
                maxlength="5"
                @input="formatData('listingPrice')"
                :readonly="formData.checked"
              >
                <template slot="append">元/吨</template>
              </el-input>
            </el-form-item>
          </div>
          <div class="line">
            <el-form-item label="支付方式" prop="payType">
              <el-select
                v-model="formData.payType"
                placeholder="请选择支付方式"
                @change="selectChange('payType')"
              >
                <el-option
                  v-for="item in payType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="配送方式" prop="deliveryMode">
              <el-select
                v-model="formData.deliveryMode"
                placeholder="请选择配送方式"
                @change="selectChange('deliveryMode')"
              >
                <el-option
                  v-for="item in deliveryMode"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="quotaList-box" v-if="formData.quotaList.length > 0">
            <h3>商品指标</h3>
            <div class="list">
              <div
                class="quota-item"
                v-for="(item, index) in formData.quotaList"
                :key="item.id"
              >
                <el-form-item
                  :label="item.dictName"
                  :prop="'quotaList.' + index + '.quotaValue'"
                  :rules="{
                    required: true,
                    message: item.dictName + '不能为空',
                    trigger: 'blur',
                  }"
                >
                  <el-input
                    v-model="item.quotaValue"
                    :placeholder="`请输入${item.dictName}`"
                    @input="formatFloat(index, 'quotaValue')"
                  >
                    <template slot="append" v-if="item.dictValue">{{
                      item.dictValue
                    }}</template>
                  </el-input>
                </el-form-item>
              </div>
            </div>
          </div>
          <div class="textarea-box">
            <el-form-item label="备注说明" prop="">
              <el-input
                type="textarea"
                v-model="formData.remark"
                :rows="3"
                maxlength="100"
                placeholder="请输入备注"
                show-word-limit
              ></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="title">其他信息</div>
        <div class="group">
          <div class="line adress">
            <el-form-item label="交割地" prop="deliveryPlace">
              <el-cascader
                v-model="formData.deliveryPlace"
                @change="selectChange('deliveryPlace')"
                :options="citys"
                placeholder="请选择交割地"
                :props="{
                  value: 'name',
                  label: 'name',
                  checkStrictly: true,
                }"
              ></el-cascader>
            </el-form-item>
            <el-form-item label="交割地详细地址" prop="">
              <el-input
                v-model.trim="formData.deliveryPlaceDetail"
                placeholder="请输入交割地详细地址"
                maxlength="100"
              ></el-input>
            </el-form-item>
          </div>
          <div class="line adress">
            <el-form-item label="产地" prop="originPlace">
              <el-cascader
                v-model="formData.originPlace"
                :options="citys"
                placeholder="请选择产地"
                @change="selectChange('originPlace')"
                :props="{
                  value: 'name',
                  label: 'name',
                  checkStrictly: true,
                }"
              ></el-cascader>
            </el-form-item>
            <el-form-item label="产地详细地址" prop="originPlaceDetail">
              <el-input
                v-model.trim="formData.originPlaceDetail"
                placeholder="请输入产地详细地址"
                maxlength="100"
              ></el-input>
            </el-form-item>
          </div>
          <div class="line line3">
            <el-form-item label="企业名称" prop="enterpriseName">
              <el-input
                v-model.trim="formData.enterpriseName"
                placeholder="请输入企业名称"
                maxlength="64"
              ></el-input>
            </el-form-item>
            <el-form-item label="联系人" prop="contacts">
              <el-input
                v-model.trim="formData.contacts"
                placeholder="请输入联系人"
                maxlength="20"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="联系方式"
              prop="contactInfo"
              class="contactInfo"
              label-width="90px"
            >
              <el-input
                v-model.trim="formData.contactInfo"
                placeholder="请输入联系方式"
                maxlength="11"
                @input="formatData('contactInfo')"
              ></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="title">拼团交易</div>
        <div class="group">
          <div class="line">
            <el-form-item label="开始时间" prop="groupStartTime">
              <el-date-picker
                v-model="formData.groupStartTime"
                type="datetime"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="请选择拼团开始时间"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="结束时间" prop="groupEndTime">
              <el-date-picker
                v-model="formData.groupEndTime"
                type="datetime"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="请选择拼团结束时间"
              >
              </el-date-picker>
            </el-form-item>
          </div>
          <el-form-item
            label="拼团价格设置"
            prop="priceList"
            class="contactInfo"
            label-width="120px"
            style="margin-left: 20px"
           
          >
            <el-button @click="newAdd">增加</el-button>
            <el-table
              border
              :data="tableData"
              style="width: 241px; margin-top: 10px"
            >
              <el-table-column
                label="数量"
                prop="groupCount"
                width="120"
                align="center"
                
              >
              </el-table-column>
              <el-table-column
                label="元/吨"
                prop="groupPrice"
                width="120"
                align="center"
              >
              </el-table-column>
            </el-table>
          </el-form-item>
        </div>
        <div class="btn-wrap">
          <el-button type="primary" @click="submit" class="hy-btn"
            >立即发布</el-button
          >
        </div>
      </el-form>
    </div>

    <el-dialog
      title="拼团挡位及价格设置"
      :visible.sync="dialogVisible"
      width="30%"
      style="text-align: center"
    >
      <div>
        <el-form
          ref="check"
          :model="priceList"
          label-width="100px"
          :rules="rule"
        >
          <el-form-item label="数量" prop="groupCount">
            <el-input
              v-model="priceList.groupCount"
              placeholder="请输入数量"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="价格(元/吨)"
            label-width="100px"
            prop="groupPrice"
          >
            <el-input
              v-model="priceList.groupPrice"
              placeholder="请输入单吨单价"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="add('check')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
  
  <script>
import { reqDictType, reqAddGroupGoods } from "@/api/hy";
import citys from "@/assets/json/citys";
import _ from "lodash";
import { getTreeV2 } from "@/api/Medium.js";
export default {
  data() {
    const validateContacts = (rule, value, callback) => {
      let validate = /^[\u4e00-\u9fa5]{2,20}$/;
      if (!validate.test(value)) {
        return callback(new Error("名称只能输入2-20位汉字"));
      } else {
        return callback();
      }
    };
    return {
      tableData: [],
      priceList: {
        groupCount: "",
        groupPrice: "",
      },
      dialogVisible: false,
      citys: citys,
      formData: {
        goodsName: "",
        initialCount: "",
        taxIncluded: "",
        priceType: "",
        listingPrice: "",
        remark: "",
        payType: "",
        deliveryPlace: "",
        deliveryPlaceDetail: "",
        originPlace: "",
        originPlaceDetail: "",
        enterpriseName: "",
        contacts: "",
        contactInfo: "",
        biddingNotice: "",
        groupStartTime: "",
        groupEndTime: "",
        quotaList: [],
        goodsType: "",
        setList: [],
        deliveryMode: "", //提货方式
      },
      rules: {
        goodsType: [
          { required: true, message: "请输入商品分类", trigger: "blur" },
        ],
        goodsName: [
          { required: true, message: "请输入商品名称", trigger: "blur" },
        ],
        initialCount: [
          { required: true, message: "请输入总量", trigger: "blur" },
        ],
        taxIncluded: [
          { required: true, message: "请选择价格类型", trigger: "blur" },
        ],
        priceType: [
          { required: true, message: "请选择价格类型", trigger: "blur" },
        ],
        payType: [
          { required: true, message: "请选择支付方式", trigger: "blur" },
        ],
        deliveryMode: [
          { required: true, message: "请选择配送方式", trigger: "blur" },
        ],
        listingPrice: [
          { required: true, message: "请输入单吨价格", trigger: "blur" },
        ],
        deliveryPlace: [
          { required: true, message: "请选择交割地", trigger: "blur" },
        ],
        deliveryPlaceDetail: [
          { required: true, message: "请输入交割地详细地址", trigger: "blur" },
        ],
        originPlace: [
          { required: true, message: "请选择产地", trigger: "blur" },
        ],
        originPlaceDetail: [
          { required: true, message: "请输入产地详细地址", trigger: "blur" },
        ],
        enterpriseName: [
          { required: true, message: "请输入企业名称", trigger: "blur" },
        ],
        contacts: [
          { required: true, message: "请输入联系人", trigger: "blur" },
          { validator: validateContacts, trigger: "blur" },
        ],
        contactInfo: [
          { required: true, message: "请输入联系方式", trigger: "blur" },
        ],
        groupStartTime: [
          { required: true, message: "请选择开始日期", trigger: "blur" },
        ],
        groupEndTime: [
          { required: true, message: "请选择结束日期", trigger: "blur" },
        ],
        // priceList: [
        //   { required: true, message: "请输入拼团价格", trigger: "blur" },
        // ],
      },
      rule: {
        groupCount: [
          { required: true, message: "请输入数量", trigger: "blur" },
        ],
        groupPrice: [
          { required: true, message: "请输入单吨单价", trigger: "blur" },
        ],
      },
      goodsNameList: [],
      normList: [],
      priceTypeList: [
        { value: 1, label: "坑口价" },
        { value: 2, label: "出厂价" },
        { value: 3, label: "船板价" },
        { value: 4, label: "平仓价" },
        { value: 5, label: "车板价" },
        { value: 6, label: "到厂价" },
      ],
      payType: [
        { value: 1, label: "现金" },
        { value: 2, label: "承兑" },
        { value: 3, label: "现金+承兑" },
      ],
      deliveryMode: [
        { value: 1, label: "送货" },
        { value: 2, label: "自提" },
      ],
      nameList: [],
      dictList: [],
      checked: "",
    };
  },
  created() {
    this.formData.enterpriseName = localStorage.getItem("userName");
    this.formData.contactInfo = window.localStorage.getItem("phone");
  },
  mounted() {
    console.log(this.citys);
    this.getTree();
    let routeQuery = this.$route.query;
    this.getGoodsName();
    this.getSetList();
  },
  methods: {
    newAdd() {
      this.dialogVisible = true;
      this.priceList.groupCount = "";
      this.priceList.groupPrice = "";
    },
    //增加
    add(check) {
      this.$refs[check].validate((valid) => {
        if (valid) {
          const obj = _.cloneDeep(this.priceList);
          this.tableData.push(obj);
          this.dialogVisible = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    getTree() {
      getTreeV2().then((res) => {
        if (res.code == 0) {
          this.citys = this.deleteChildren(res.data);
        }
      });
    },
    deleteChildren(arr) {
      let childs = arr;
      for (let i = childs.length; i--; i > 0) {
        if (childs[i].children) {
          if (childs[i].children.length) {
            this.deleteChildren(childs[i].children);
          } else {
            delete childs[i].children;
          }
        }
      }
      return arr;
    },
    formatFloat(index, name) {
      console.log(this.formData.quotaList[index][name]);
      this.formData.quotaList[index][name] =
        this.formData.quotaList[index][name].match(/^\d*(\.?\d{0,23})/g)[0] ||
        "";
      if (this.formData.quotaList[index][name].indexOf(".") > 0) {
        this.formData.quotaList[index][name] = this.formData.quotaList[index][
          name
        ].substring(0, this.formData.quotaList[index][name].indexOf(".") + 3);
      } else {
        this.formData.quotaList[index][name] = this.formData.quotaList[index][
          name
        ].substring(0, 20);
      }
      console.log(this.formData.quotaList[index][name]);
    },
    selectChange(name) {
      this.$refs.form.clearValidate(name);
    },
    getSetList() {
      reqDictType({ dictType: "bidding_set" })
        .then((ret) => {
          if (ret.code == 0) {
            this.formData.setList = ret.data.children;
            console.log(this.setList, "-----this.setList----");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    goodsChange(e) {
      outer: for (let i = 0; i < this.dictList.length; i++) {
        let item = this.dictList[i];
        if (item.id == e) {
          item.children.forEach((items) => {
            this.$set(items, "quotaValue", "");
          });
          this.goodsName = item.dictName;
          this.formData.quotaList = item.children.map((item) => {
            let arr = item.dictValue.split(";");
            return {
              ...item,
              dictName: item.dictName + "(" + arr[0] + ")" + arr[2],
              dictValue: arr[1],
            };
          });
          console.log(this.formData.quotaList);
          break outer;
        }
      }
      if (e.length > 0) {
        this.$refs.form.clearValidate("goodsName");
      }
    },
    formatData(name) {
      this.formData[name] = this.formData[name].match(/^\d*/g)[0] || "";
      if (this.formData[name] == 0) {
        this.formData[name] = "";
      }
    },
    getGoodsName() {
      reqDictType({ dictType: "coal_type" })
        .then((ret) => {
          if (ret.code == 0) {
            this.dictList = JSON.parse(JSON.stringify(ret.data.children));
            let data = ret.data.children;
            for (let i = 0; i < data.length; i++) {
              let item = data[i];
              for (let j = 0; j < item.children.length; j++) {
                let sub = item.children[j];
                delete sub.children;
              }
            }
            this.nameList = data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    submit() {
      this.formData.priceList = this.tableData;
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submitFn();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    initSubmitData() {
      let data = {
        ...this.formData,
      };
      // data.goodsType = data.goodsType[1];
      data.deliveryPlace = data.deliveryPlace.join(",");
      data.originPlace = data.originPlace.join(",");
      // data.goodsName = this.goodsName;
      data.quotaList = data.quotaList.map((item) => {
        return {
          quotaId: item.id,
          quotaValue: item.quotaValue,
        };
      });
      data.setList = data.setList.map((item) => {
        return {
          setId: item.id,
          setValue: !item.checked ? 0 : 1,
        };
      });
      return data;
    },
    submitFn() {
      if (this.submiting) return;
      this.submiting = true;
      let data = this.initSubmitData();
      reqAddGroupGoods(data)
        .then((ret) => {
          if (ret.code == 0) {
            this.$confirm("发布成功,请等待平台审核", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "关闭",
              cancelButtonClass: "hy-confirm-cancel-btn",
              type: "warning",
              customClass: "hy-confirm",
              iconClass: "hy-icon-jingjia",
            })
              .then(() => {
                this.$router.go(-1);
              })
              .catch(() => {
                this.$router.go(-1);
              });
          } else {
            this.$message({
              type: "error",
              message: ret.message,
            });
          }
          this.submiting = false;
        })
        .catch((err) => {
          this.submiting = false;
          console.log(err);
        });
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.add-goods {
  width: 1195px !important;
  margin: 14px auto !important;
  background: white !important;
  border: 1px solid #e5e5e5 !important;
  border-top: none !important;

  &::before,
  &::after {
    display: table !important;
    content: "" !important;
  }
}

.title {
  font-size: 18px !important;
  font-weight: bold !important;
  font-family: PingFang SC !important;
  padding: 14px 15px !important;
  border: 1px solid #e5e5e5 !important;
  border-left: none !important;
  border-right: none !important;
  background: #f7f7f7 !important;
  display: flex !important;
  align-items: center !important;
}

.btn-wrap {
  text-align: center !important;
  margin-bottom: 35px !important;

  .hy-btn {
    width: 193px !important;
  }
}

.floorPrice,
.bond {
  .el-input {
    width: 276px !important;
  }
}

.listingPrice .el-checkbox {
  margin-left: 20px !important;
}

.textarea-box {
  width: 100% !important;
}

.quotaList-box {
  h3 {
    font-size: 14px !important;
    color: #606266 !important;
    line-height: 40px !important;
    font-weight: normal !important;
    width: 140px !important;
    text-align: right !important;
    padding-right: 12px !important;
  }

  .list {
    display: flex !important;
    align-items: center !important;
    flex-wrap: wrap !important;

    .quota-item {
      width: 50% !important;
      flex-shrink: 0 !important;
    }
  }
}

.group {
  padding: 32px 39px !important;
  background: white !important;
}

.line {
  display: flex !important;
  align-items: center !important;

  .el-input,
  .el-select,
  .el-cascader {
    width: 275px !important;
  }
}

.adress {
  .el-form-item:nth-child(2) {
    .el-input {
      width: 564px !important;
    }
  }
}

.line3 .contactInfo {
  .el-input {
    width: 200px !important;
  }
}

.pirceType {
  display: flex !important;
  align-items: center !important;

  .el-select,
  .el-input {
    width: 137.5px !important;
  }
}

.checke-box {
  display: flex !important;
  align-items: center !important;
  flex-wrap: wrap !important;
  justify-content: space-between !important;
  padding: 0 35px !important;

  .checke-item {
    width: 494px !important;
    border-bottom: 1px dashed #e5e5e5 !important;
    padding-bottom: 10px !important;
    margin-bottom: 20px !important;

    &::v-deep .el-checkbox__label {
      color: #2079fb;
      font-family: FZLanTingHei-R-GBK;
    }

    h6 {
      color: #2079fb;
      font-weight: normal;
      font-family: FZLanTingHei-R-GBK;

      i {
        color: #2079fb;
        margin-right: 10px;
      }
    }

    p {
      line-height: 30px !important;
    }

    .tip {
      color: #fb4d4d;
      font-family: FZLanTingHei-R-GBK;
      font-size: 12px;
      padding-left: 25px;
    }
  }
}
</style>