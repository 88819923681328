<template>
  <div>
    <div class="mall">
      <div class="aside">
        <div class="t">产品分类</div>
        <el-scrollbar style="height: calc(100% - 51px)">
          <Left @select="typeSearch" />
        </el-scrollbar>
      </div>
      <div class="content">
        <div class="search">
          <div class="form">
            <el-input
              v-model="searchData.enterpriseName"
              placeholder="请输入公司名称"
              maxLength="99"
            ></el-input>
            <el-cascader
              v-model="searchData.producer"
              :options="citys"
              placeholder="请选择产地"
              :props="{
                value: 'label',
                label: 'label',
              }"
            ></el-cascader>
            <el-select
              v-model="searchData.groupStatus"
              placeholder="拼团状态"
              style="width: 300px; margin-left: 10px"
            >
              <el-option
                placeholder="请选择拼团状态"
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-button @click="searchFn" class="hy-btn">搜索</el-button>
            <el-button @click="resetFn" class="cl-btn">清除</el-button>
          </div>
        </div>

        <div class="sort-box">
          <div class="left">
            <div class="items" @click="sort('releaseDateOrderBy')">
              <span>发布日期</span>
              <span class="caret-wrapper">
                <i
                  class="el-icon-caret-top"
                  :class="{ active: searchData.releaseDateOrderBy == 1 }"
                ></i>
                <i
                  class="el-icon-caret-bottom"
                  :class="{ active: searchData.releaseDateOrderBy == 0 }"
                ></i>
              </span>
            </div>
            <div class="items" @click="sort('priceOrderBy')">
              <span>价格</span>
              <span class="caret-wrapper">
                <i
                  class="el-icon-caret-top"
                  :class="{ active: searchData.priceOrderBy == 1 }"
                ></i>
                <i
                  class="el-icon-caret-bottom"
                  :class="{ active: searchData.priceOrderBy == 0 }"
                ></i>
              </span>
            </div>
          </div>
          <div class="right">
            <el-button @click="add" class="hy-btn"> 发布拼团交易 </el-button>
          </div>
        </div>
        <div class="list" v-if="tableList.list.length > 0">
          <div
            class="items"
            v-for="item in tableList.list"
            :key="item.goodsId"
            @click="detail(item)"
          >
            <div class="item-title f-hide item_type">
              <!-- <div style="width:300px;text-overflow:ellipsis;white-space: nowrap;overflow: hidden;">{{ item.enterpriseName }}</div> -->
              <el-popover
                placement="top-start"
                title=""
                width="200"
                trigger="hover"
                :content="item.enterpriseName"
              >
                <div
                  slot="reference"
                  style="
                    width: 220px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                  "
                >
                  {{ item.enterpriseName }}
                </div>
              </el-popover>
              <div style="font-size: 14px; color: #999; font-weight: normal">
                {{ item.groupStatus | groupStatus }}
              </div>
            </div>
            <div class="info">
              <div class="info-left">
                <p class="f-hide">{{ item.goodsName }}</p>
                <p class="f-hide">{{ item.stockCount }}吨</p>
              </div>
              <div class="price" :class="[+item.price == 0 ? 'no-price' : '']">
                {{ item.listingPrice | priceFilter }}
              </div>
            </div>
            <div class="indicator f-hide2" v-if="item.title">
              <el-tooltip
                class="item"
                effect="dark"
                :content="item.title"
                placement="top-start"
              >
                <span
                  >商品指标：
                  <span class="indicator_span">{{ item.title }}</span></span
                >
              </el-tooltip>
            </div>
            <div class="origin f-hide2">
              <i class="hy-icon-shouhuodizhi"></i>
              {{ item.originPlace }}{{ item.originPlaceDetail }}
            </div>
          </div>
        </div>

        <div class="nodataImg" v-else>
          <img src="../../assets/zxx_img/nodata.png" alt="" />
        </div>
        <!-- @size-change="handleSizeChange" -->

        <el-pagination
          v-show="tableList.total > 0"
          @current-change="handleCurrentChange"
          :currentPage="searchData.page"
          :page-size="searchData.size"
          layout="total, prev, pager, next, jumper"
          :total="tableList.total"
          background
        ></el-pagination>
      </div>
    </div>
  </div>
</template>
  
  <script>
import citys from "@/assets/json/citys";
import { groupTransactionList } from "@/api/hy";
export default {
  data() {
    return {
      citys: citys,
      searchData: {
        pageNum: 1,
        pageSize: 9,
        enterpriseId: 1,
        enterpriseName: "",
        goodsName: "",
        goodsNameOrNum: "",
        goodsNum: "",
        groupStatus: "",
        producer: "",
        searchType: "",
      },
      tableList: {
        list: [],
        total: 0,
      },
      searchDataOriginPlace: "",
      options: [
        {
          value: 1,
          label: "可拼团",
        },
        {
          value: 2,
          label: "拼团成功",
        },
        {
          value: 3,
          label: "拼团失败",
        },
      ],
    };
  },
  components: {
    Left: () => import("../mall/components/left.vue"),
  },
  watch: {
    $route: {
      handler() {
        // 在mounted函数执行的方法，放到该处
        this.searchData.page = 1;
        this.searchData.goodsType = "";
        this.getList();
      },
      deep: true,
    },
  },

  created() {},
  mounted() {
    this.searchData.selectTradeType = this.$route.query.selectTradeType;
    this.searchData.selectOrderType = this.$route.query.selectOrderType;
    this.getList();
  },
  filters: {
    priceFilter(val) {
      if (+val == 0) return "￥面议";
      return `${val}元/吨`;
    },
    groupStatus(val) {
      switch (val) {
        case 0:
          return "未开始";
          break;
        case 1:
          return "可拼团";
          break;
        case 2:
          return "拼团成功";
          break;
        case 3:
          return "拼团失败";
          break;
      }
    },
  },
  methods: {
    add() {
      this.$router.push({
        path: "/piecingTrade",
      });
    },
    detail(item) {
      console.log(item, "00000000000000");
      this.$router.push({
        path: "/piecingDetails",
        query: {
          goodsId: item.goodsId,
        },
      });
    },
    getList() {
      this.searchData.selectTradeType = this.$route.query.selectTradeType;
      this.searchData.selectOrderType = this.$route.query.selectOrderType;
      groupTransactionList(this.searchData)
        .then((ret) => {
          let list = ret.data.list;
          if (ret.code == 0) {
            this.tableList.list = list;
            this.tableList.total = ret.data.total;
          } else {
            this.tableList.list = [];
            this.tableList.total = 0;
          }
        })
        .catch((err) => {
          this.tableList.list = [];
          this.tableList.total = 0;
        });
    },
    handleCurrentChange(val) {
      this.searchData.page = val;
      this.getList();
    },
    typeSearch(val) {
      this.searchData.page = 1;
      this.searchData.goodsType = val;
      this.getList();
    },
    searchFn() {
      this.searchData.page = 1;
      if (this.searchData.producer) {
        this.searchData.producer = this.searchData.producer.join(",");
      }
      // if(this.searchData.groupStatus){
      //   this.searchData.groupStatus=Number(this.searchData.groupStatus)
      // }
      this.getList();
    },
    resetFn() {
      this.searchData.page = 1;
      this.searchData.enterpriseName = "";
      this.searchData.producer = "";
      this.searchData.groupStatus = "";
      this.getList();
    },
    sort(tag) {
      this.searchData[tag] = !!this.searchData[tag] ? 0 : 1;
      let obj = {
        releaseDateOrderBy: "priceOrderBy",
        priceOrderBy: "releaseDateOrderBy",
      };
      this.searchData[obj[tag]] = null;
      this.getList();
    },
  },
};
</script>
  
  <style lang="scss" scoped>
::v-deep .el-link--inner {
  font-size: 14px;
  line-height: 50px;
  height: 50px;
}

.indicator_span {
}

.mall {
  width: 1195px;
  margin: 14px auto;
  display: flex;

  &::before,
  &::after {
    display: table;
    content: "";
  }
}

.aside {
  // min-height: 500px;
  height: calc(100vh - 146px);
  border: 1px solid #e5e5e5;
  background: white;
  width: 186px;
  flex-shrink: 0;
  overflow: hidden;

  &::v-deep {
    .el-scrollbar__wrap {
      overflow-x: hidden;
    }
  }

  .t {
    line-height: 1;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    border-bottom: 1px solid #ebebeb;
    background: #f5f5f5;
    padding: 16px 0;
    overflow: hidden;
  }
}

.search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px;

  .form {
    display: flex;
    width: 100%;

    .el-cascader {
      margin-left: 10px;
      width: 350px;
    }

    .hy-btn {
      margin-left: 10px;
      width: 20%;
    }

    .cl-btn {
      width: 20%;
    }
  }
}

.sort-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 25px;
  border: 1px solid #ebebeb;
  border-left: none;
  border-right: none;
  background: #f7f7f7;

  .left {
    display: flex;

    .items {
      display: flex;
      align-items: center;
      cursor: pointer;

      .caret-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-left: 5px;
        font-size: 14px;

        .active {
          color: #2484d6;
        }
      }

      & + .items {
        margin-left: 20px;
      }
    }
  }
}

.content {
  min-height: 500px;
  border: 1px solid #ebebeb;
  margin-left: 10px;
  background: white;
  flex: 1;
}

.item_type {
  display: flex;
  justify-content: space-between;
}

.list {
  display: flex;
  flex-wrap: wrap;
  padding: 16px 20px;
  padding-bottom: 0;

  .items {
    border: 1px solid #ebebeb;
    min-width: 276px;
    width: 304px;
    margin: 0 20px 20px 0;
    padding: 10px;
    cursor: pointer;
    // background: linear-gradient(-45deg, #00A0FF, #0A65FF);
    box-shadow: 0px 5px 20px 0px rgba(143, 170, 249, 0.47);
    border-radius: 10px;

    &:hover {
      background: linear-gradient(-45deg, #00a0ff, #0a65ff);
      color: white;
    }

    &:nth-child(3n) {
      margin-right: 0 !important;
    }

    &:hover {
      border: 1px solid #2084d6;
    }

    .item-title {
      font-size: 16px;
      font-weight: bold;
      padding: 10px 4px;
      border-bottom: 1px solid #e5e5e5;
    }

    .info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 13px 3px;

      p {
        width: 150px;
      }

      .price {
        flex-shrink: 0;
      }
    }

    .origin {
      margin-top: 20px;
    }
  }
}

.el-pagination {
  text-align: right;
  margin: 0 20px 20px 0;
}

.f-hide {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
}

.f-hide2 {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.no-price,
.price {
  color: #fe7b35;
  font-size: 18px;
}

.info-left {
  .f-hide {
    & + .f-hide {
      margin-top: 5px;
    }
  }
}

.indicator {
  // padding: 0 10px;
  // display: flex;
}

.nodataImg {
  padding: 200px 0;
  text-align: center;
}
</style>