var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"goods-detail"},[_c('div',{staticClass:"top"},[_c('Particulars',{ref:"detail",on:{"child-event":_vm.bindDeatil}}),(
          (_vm.detailData.tradeBidding &&
            _vm.detailData.tradeBidding.biddingStatus == 1) ||
          (_vm.detailData.tradeBidding &&
            _vm.detailData.tradeBidding.biddingStatus == 0) ||
          (_vm.detailData.tradeGoodsListing &&
            _vm.detailData.tradeGoodsListing.listingStatus == 0)
        )?_c('div',{staticClass:"form-box"},[_c('div',{staticClass:"form"},[_c('el-form',{ref:"form",attrs:{"model":_vm.ruleForm,"rules":_vm.rules,"label-width":"90px"}},[(_vm.selectTradeType == 1 && _vm.detailData.listType == 0)?_c('el-form-item',{staticClass:"offerPrice",attrs:{"label":"下单数量","label-width":"100px","prop":"num"}},[_c('i',{staticClass:"el-icon-minus",on:{"click":_vm.subNum}}),_c('el-input',{attrs:{"disabled":_vm.isinitialCount,"maxlength":"2100000000"},on:{"input":function($event){return _vm.formatData('num')}},model:{value:(_vm.ruleForm.num),callback:function ($$v) {_vm.$set(_vm.ruleForm, "num", $$v)},expression:"ruleForm.num"}}),_c('i',{staticClass:"el-icon-plus",on:{"click":_vm.addNum}}),_c('span',[_vm._v("吨")])],1):_vm._e(),_vm._l((_vm.ruleForm.offerDetailList),function(item,index){return _c('div',{key:index},[(_vm.selectTradeType == 2 && _vm.detailData.listType == 0)?_c('el-form-item',{staticClass:"offerPrice",attrs:{"label":index == 0 ? '报价金额' : '',"prop":'offerDetailList.' + index + '.offerPrice',"rules":{
                  required: true,
                  message: '请输入报价金额',
                  trigger: 'blur',
                },"label-width":"150px"}},[_c('div',{staticClass:"offerPriceItem"},[_c('i',{staticClass:"el-icon-minus",on:{"click":function($event){return _vm.sub(index)}}}),_c('el-input',{model:{value:(_vm.ruleForm.offerDetailList[index].offerPrice),callback:function ($$v) {_vm.$set(_vm.ruleForm.offerDetailList[index], "offerPrice", $$v)},expression:"ruleForm.offerDetailList[index].offerPrice"}}),_c('i',{staticClass:"el-icon-plus",on:{"click":function($event){return _vm.add(index)}}}),_c('span',[_vm._v("元/吨")])],1)]):_vm._e()],1)}),(
                _vm.selectTradeType == 2 &&
                _vm.selectOrderType == 1 &&
                _vm.detailData.listType == 0
              )?_c('div',{staticStyle:{"margin-left":"160px","color":"red"}},[_vm._v(" 最低加价"+_vm._s(_vm.detailData.tradeBidding.lowestDiffPrice)+"元 ")]):_vm._e(),(
                _vm.selectTradeType == 2 &&
                _vm.selectOrderType == 2 &&
                _vm.detailData.listType == 0
              )?_c('div',{staticStyle:{"margin-left":"160px","color":"red"}},[_vm._v(" 最低降价"+_vm._s(_vm.detailData.tradeBidding.lowestDiffPrice)+"元 ")]):_vm._e()],2)],1),_c('div',{staticClass:"btn-wrap"},[(
              _vm.selectTradeType == 1 &&
              _vm.selectOrderType == 1 &&
              _vm.detailData.listType == 0
            )?_c('el-button',{staticClass:"add hy-btn",on:{"click":function($event){return _vm.createOrder(2)}}},[_vm._v("确认采购")]):_vm._e(),(
              _vm.selectTradeType == 1 &&
              _vm.selectOrderType == 2 &&
              _vm.detailData.listType == 0
            )?_c('el-button',{staticClass:"add hy-btn",on:{"click":function($event){return _vm.createOrder(1)}}},[_vm._v("确认供货")]):_vm._e(),(
              _vm.selectTradeType == 2 && _vm.detailData.listType == 0 && _vm.payBond == 1
            )?_c('el-button',{staticClass:"add hy-btn",on:{"click":function($event){return _vm.openPayBond(2)}}},[_vm._v("立即报价")]):_vm._e(),(
              _vm.selectTradeType == 2 &&
              _vm.detailData.listType == 0 &&
              _vm.payBond == 0 &&
              (_vm.detailData.tradeBidding.partNeedAudit == 0 ||
                (_vm.detailData.tradeBidding.partNeedAudit == 1 &&
                  _vm.partAuditStatus == 1))
            )?_c('el-button',{staticClass:"add hy-btn",on:{"click":function($event){return _vm.openPayBond(1)}}},[_vm._v("支付保证金")]):_vm._e(),(
              _vm.selectTradeType == 2 &&
              _vm.detailData.listType == 0 &&
              _vm.payBond == 0 &&
              _vm.detailData.tradeBidding.partNeedAudit == 1 &&
              _vm.partAuditStatus != 1
            )?_c('el-button',{staticClass:"add hy-btn",attrs:{"disabled":_vm.partAuditStatus == 0},on:{"click":function($event){return _vm.participateBidding()}}},[_vm._v(_vm._s(_vm.formatApplyVal))]):_vm._e(),(_vm.detailData.listType == 0 && _vm.detailData.followFlag == 0)?_c('el-button',{staticClass:"follow",attrs:{"icon":"el-icon-plus"},on:{"click":_vm.follow}},[_vm._v("收藏商品")]):_vm._e(),(_vm.detailData.listType == 0 && _vm.detailData.followFlag == 1)?_c('el-button',{staticClass:"follow",attrs:{"icon":"el-icon-plus"},on:{"click":_vm.follows}},[_vm._v("取消收藏")]):_vm._e()],1),(_vm.detailData.residueTime && _vm.detailData.residueTime != '0')?_c('div',[_c('div',{staticStyle:{"margin-left":"20px"}},[_vm._m(0),(_vm.detailData.residueTime)?_c('CountDown',{attrs:{"endDate":`${_vm.detailData.residueTime}`}}):_vm._e()],1)]):_vm._e(),(
            _vm.detailData.distanceStartTime &&
            _vm.detailData.distanceStartTime != '0'
          )?_c('div',[_c('div',{staticStyle:{"margin-left":"20px"}},[_vm._m(1),(_vm.detailData.distanceStartTime)?_c('CountDown',{attrs:{"endDate":`${_vm.detailData.distanceStartTime}`}}):_vm._e()],1)]):_vm._e()]):_vm._e()],1),(_vm.selectTradeType == 2)?_c('div',[(_vm.computedLoginAndAuthentication)?_c('div',{staticClass:"list"},[_c('div',{staticClass:"t"},[_c('div',[_vm._v("竞价公司")]),_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":_vm.flushClick}},[_c('i',{staticClass:"el-icon-refresh"})])]),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData.list,"span-method":_vm.objectSpanMethod,"border":""}},[_c('el-table-column',{attrs:{"align":"center","prop":"companyName","label":"竞价公司"}}),_c('el-table-column',{attrs:{"align":"center","prop":"goodsName","label":"竞价商品名称"}}),_c('el-table-column',{attrs:{"align":"center","prop":"offerPrice","label":"报价金额"}}),_c('el-table-column',{attrs:{"align":"center","prop":"offerCount","label":"报价数量(吨)"}}),_c('el-table-column',{attrs:{"prop":"createTime","align":"center","label":"报价时间"}}),_c('el-table-column',{attrs:{"align":"center","prop":"winBidding","label":"中标状态","width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.winBidding == 1)?_c('div',[_c('img',{attrs:{"src":require("../../assets/zxx_img/zhongbiao.png"),"alt":""}})]):_vm._e(),(scope.row.winBidding == 0)?_c('div',[_c('div',[_vm._v("未中标")])]):_vm._e(),(scope.row.winBidding == -1)?_c('div',[_c('div',[_vm._v("竞拍中")])]):_vm._e()]}}],null,false,505299427)})],1),_c('el-pagination',{attrs:{"background":"","layout":"prev, pager, next","total":_vm.tableData.total,"page-size":_vm.search.size,"current-page":_vm.search.page},on:{"current-change":_vm.handleCurrentChange,"update:currentPage":function($event){return _vm.$set(_vm.search, "page", $event)},"update:current-page":function($event){return _vm.$set(_vm.search, "page", $event)}}})],1):_vm._e(),(
          _vm.detailData.ifPublisher && _vm.detailData.tradeBidding.partNeedAudit == 1
        )?_c('div',{staticClass:"list"},[_c('div',{staticClass:"t"},[_c('div',[_vm._v("审核参与竞拍企业")]),_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":_vm.listPartBidding}},[_c('i',{staticClass:"el-icon-refresh"})])]),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.partBiddingData,"border":""}},[_c('el-table-column',{attrs:{"align":"center","prop":"enterpriseName","label":"竞价公司"}}),_c('el-table-column',{attrs:{"align":"center","prop":"telephone","label":"注册电话"}}),_c('el-table-column',{attrs:{"prop":"createTime","align":"createTime","label":"提交时间"}}),_c('el-table-column',{attrs:{"align":"center","prop":"auditStatus","label":"状态","width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.auditStatus == 0)?_c('div',[_c('div',[_vm._v("待审核")])]):_vm._e(),(scope.row.auditStatus == 1)?_c('div',[_c('div',[_vm._v("审核通过")])]):_vm._e(),(scope.row.auditStatus == 2)?_c('div',[_c('div',[_vm._v("审核拒绝")])]):_vm._e()]}}],null,false,3992279243)}),_c('el-table-column',{attrs:{"align":"center","prop":"winBidding","label":"操作","width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
                  scope.row.auditStatus == 0 &&
                  (_vm.detailData.tradeBidding?.biddingStatus == 0 ||
                    _vm.detailData.tradeBidding?.biddingStatus == 1)
                )?_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.Approved(scope.row, 1)}}},[_vm._v("审核通过")]):_vm._e(),(
                  scope.row.auditStatus == 0 &&
                  (_vm.detailData.tradeBidding?.biddingStatus == 0 ||
                    _vm.detailData.tradeBidding?.biddingStatus == 1)
                )?_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.Approved(scope.row, 2)}}},[_vm._v("审核拒绝")]):_vm._e()]}}],null,false,2684165189)})],1)],1):_vm._e(),_c('div',{staticClass:"rule"},[_c('regulation',{attrs:{"data":_vm.detailData}})],1)]):_vm._e(),_c('div',{staticClass:"evaluate"})]),_c('PaymentBond',_vm._g(_vm._b({attrs:{"data":_vm.config,"isShow":_vm.paymentBondShow},on:{"close":function($event){return _vm.closeDialog('paymentBondShow')},"paymentSuccess":_vm.paymentSuccessFn}},'PaymentBond',_vm.$attrs,false),_vm.$listeners))],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"flex","align-items":"center","margin-bottom":"9px","font-size":"16px","color":"#333"}},[_c('div',{staticStyle:{"width":"53px","height":"1px","background":"#2a8de9","display":"inline-block"}}),_c('div',{staticStyle:{"margin":"0 5px"}},[_vm._v("距离结束还有")]),_c('div',{staticStyle:{"width":"53px","height":"1px","background":"#2a8de9","display":"inline-block"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"flex","align-items":"center","margin-bottom":"9px","font-size":"16px","color":"#333"}},[_c('div',{staticStyle:{"width":"53px","height":"1px","background":"#2a8de9","display":"inline-block"}}),_c('div',{staticStyle:{"margin":"0 5px"}},[_vm._v("距离开始还有")]),_c('div',{staticStyle:{"width":"53px","height":"1px","background":"#2a8de9","display":"inline-block"}})])
}]

export { render, staticRenderFns }