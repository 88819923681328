<template>
  <div>
    <div class="add-goods">
      <el-form ref="form" :model="formData" :rules="rules" label-width="110px">
        <div class="title">基本信息</div>
        <div class="group info-box">
          <div
            class="line goods-list"
            v-for="(goods, index) in formData.goodsCategoryList"
            :key="goods.key"
          >
            <el-form-item
              label="商品分类"
              label-width="110px"
              :prop="'goodsCategoryList.' + index + '.goodsType'"
              :rules="{
                required: true,
                message: '请选择商品分类',
                trigger: 'change',
              }"
            >
              <el-select
                v-model="formData.goodsCategoryList[index].goodsType"
                @change="goodsChange($event, goods, index)"
                placeholder="请选择商品分类"
              >
                <el-option
                  v-for="item in nameList"
                  :key="item.id"
                  :label="item.dictName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="商品名称"
              label-width="110px"
              :prop="'goodsCategoryList.' + index + '.goodsName'"
              :rules="{
                required: true,
                message: '请输入商品名称',
                trigger: 'blur',
              }"
            >
              <!-- <el-cascader v-model="formData.goodsName" :options="nameList" :props="{
                  value: 'id',
                  label: 'dictName',
                }" placeholder="请选择商品名称" @change="goodsChange"></el-cascader> -->
              <el-input
                v-model="formData.goodsCategoryList[index].goodsName"
                placeholder="请输入商品名称"
              >
              </el-input>

              <!-- <el-select v-model="formData.goodsName" @change="goodsChange" placeholder="请选择商品名称">
                <el-option v-for="item in nameList" :key="item.id" :label="item.dictName" :value="item.id">
                </el-option>
              </el-select> -->
            </el-form-item>
            <el-form-item
              label="竞价底价"
              label-width="110px"
              :prop="'goodsCategoryList.' + index + '.goodsPrice'"
              :rules="{
                required: !formData.goodsCategoryList[index].checked,
                message: '请输入价格',
                trigger: 'blur',
              }"
            >
              <el-input
                v-model="formData.goodsCategoryList[index].goodsPrice"
                placeholder="请输入竞价底价"
                maxlength="5"
                @input="formatDatas(index, 'goodsPrice')"
                :readonly="formData.checked"
              >
                <template slot="append">元/吨</template>
              </el-input>
            </el-form-item>
            <el-form-item
              label="数量"
              label-width="110px"
              :prop="'goodsCategoryList.' + index + '.number'"
              :rules="{
                required: true,
                message: '请输入数量',
                trigger: 'blur',
              }"
            >
              <el-input
                class="number"
                v-model.trim="formData.goodsCategoryList[index].number"
                placeholder="请输入数量"
                @input="formatDatas(index, 'number')"
                maxlength="7"
                min="1"
              >
                <template slot="append">吨</template>
              </el-input>
            </el-form-item>
            <!-- <el-button
              v-if="goods.key == 0"
              style="margin-left: 10px"
              @click="addGoodes()"
              >增加</el-button
            >
            <el-button
              v-if="goods.key != 0"
              style="margin-left: 10px"
              @click="delGoods(goods)"
              >删除</el-button
            > -->
          </div>
          <!-- <div class="line"></div> -->
          <div class="line col2" style="margin-top: 10px">
            <el-form-item
              label="配送方式"
              label-width="110px"
              prop="deliveryMode"
            >
              <el-select
                v-model="formData.deliveryMode"
                placeholder="请选择配送方式"
                @change="selectChange('deliveryMode')"
              >
                <el-option
                  v-for="item in deliveryMode"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="支付方式" label-width="110px" prop="payType">
              <el-select
                v-model="formData.payType"
                placeholder="请选择支付方式"
                @change="selectChange('payType')"
              >
                <el-option
                  v-for="item in payType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="line col2">
            <el-form-item label="保证金" label-width="110px" prop="bond">
              <el-input
                v-model="formData.bond"
                placeholder="请输入保证金"
                @input="formatData('bond')"
                @blur="vaildZero('bond')"
              >
                <template slot="append">万元</template>
              </el-input>
            </el-form-item>

            <div class="pirceType">
              <el-form-item
                label="价格类型"
                label-width="110px"
                prop="taxIncluded"
              >
                <el-select
                  v-model="formData.taxIncluded"
                  placeholder="是否含税"
                  @change="selectChange('taxIncluded')"
                >
                  <el-option label="含税" :value="0"></el-option>
                  <el-option label="不含税" :value="1"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="" prop="priceType" label-width="0">
                <el-select
                  v-model="formData.priceType"
                  placeholder="请选择类型"
                  @change="selectChange('priceType')"
                >
                  <el-option
                    v-for="item in priceTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <!-- <el-form-item label="商品分类" prop="goodsType">
              <el-select
                v-model="formData.goodsType"
                @change="goodsChange"
                placeholder="请选择商品分类"
              >
                <el-option
                  v-for="item in nameList"
                  :key="item.id"
                  :label="item.dictName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item> -->
          </div>
          <div class="line col2">
            <el-form-item
              class="normal"
              label="是否需要审核后参与竞价"
              prop="partNeedAudit"
            >
              <div class="radio-group">
                <el-radio v-model="formData.partNeedAudit" :label="1">
                  是
                </el-radio>
                <el-radio v-model="formData.partNeedAudit" :label="0">
                  否
                </el-radio>
              </div>
            </el-form-item>
            <el-form-item
              label="交货期限"
              label-width="110px"
              prop="deliveryPeriod"
            >
              <el-input
                v-model="formData.deliveryPeriod"
                placeholder="请输入交货期限"
                @input="formatData('deliveryPeriod')"
              >
                <template slot="append">天</template>
              </el-input>
            </el-form-item>
          </div>
          <div class="line col2" style="margin-top: 10px">
            <el-form-item
              class="normal"
              label="是否配置最低参与人数"
              prop="ifMinNumber"
            >
              <div class="radio-group">
                <el-radio
                  v-model="formData.ifMinNumber"
                  :label="1"
                  @input="handleIfMinNumber"
                >
                  是
                </el-radio>
                <el-radio
                  v-model="formData.ifMinNumber"
                  :label="0"
                  @input="handleIfMinNumber"
                >
                  否
                </el-radio>
              </div>
            </el-form-item>
            <el-form-item
              label="最低参与人数"
              label-width="110px"
              prop="minNumber"
              v-if="formData.ifMinNumber == 1"
            >
              <el-input
                v-model="formData.minNumber"
                placeholder="请输入最低参与人数"
                @input="formatData('minNumber')"
              >
              </el-input>
            </el-form-item>
          </div>
          <div
            v-for="(goods, goodsIndex) in formData.goodsCategoryList"
            :key="'goods' + goods.key"
          >
            <div class="quotaList-box" v-if="goods.quotaList.length > 0">
              <h3>{{ goods.dictName }}商品指标</h3>
              <div class="list">
                <div
                  class="quota-item"
                  v-for="(item, index) in goods.quotaList"
                  :key="item.id"
                >
                  <el-form-item
                    :label="item.dictName"
                    :prop="
                      'goodsCategoryList.' +
                      goodsIndex +
                      '.quotaList.' +
                      index +
                      '.quotaValue'
                    "
                    :rules="{
                      required: item.ifMust == 0,
                      message: item.dictName + '不能为空',
                      trigger: 'blur',
                    }"
                  >
                    <el-input
                      v-model.trim="item.quotaValue"
                      :placeholder="`请输入${item.dictName}`"
                      @input="formatFloat(goodsIndex, index, 'quotaValue')"
                    >
                      <template slot="append" v-if="item.dictValue">{{
                        item.dictValue
                      }}</template>
                    </el-input>
                  </el-form-item>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="quotaList-box" v-if="formData.quotaList.length > 0">
            <h3>商品指标</h3>
            <div class="list">
              <div
                class="quota-item"
                v-for="(item, index) in formData.quotaList"
                :key="item.id"
              >
                <el-form-item
                  :label="item.dictName"
                  label-width="110px"
                  :prop="'quotaList.' + index + '.quotaValue'"
                  :rules="{
                    required: true,
                    message: item.dictName + '不能为空',
                    trigger: 'blur',
                  }"
                >
                  <el-input
                    v-model="item.quotaValue"
                    :placeholder="`请输入${item.dictName}`"
                    @input="formatFloat(index, 'quotaValue')"
                  >
                    <template slot="append" v-if="item.dictValue">{{
                      item.dictValue
                    }}</template>
                  </el-input>
                </el-form-item>
              </div>
            </div>
          </div> -->
          <div class="textarea-box">
            <el-form-item label="备注说明" prop="">
              <el-input
                type="textarea"
                v-model="formData.remark"
                :rows="3"
                maxlength="10000"
                placeholder="请输入备注"
              ></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="title">其他信息</div>
        <div class="group">
          <div class="line adress">
            <el-form-item label="交割地" prop="deliveryPlace">
              <el-cascader
                v-model="formData.deliveryPlace"
                @change="selectChange('deliveryPlace')"
                :options="citys"
                placeholder="请选择交割地"
                :props="{
                  value: 'name',
                  label: 'name',
                  checkStrictly: true,
                }"
              ></el-cascader>
            </el-form-item>
            <el-form-item label="交割地详细地址" prop="">
              <el-input
                v-model.trim="formData.deliveryPlaceDetail"
                placeholder="请输入交割地详细地址"
                maxlength="100"
              ></el-input>
            </el-form-item>
          </div>
          <div class="line adress">
            <el-form-item label="产地" prop="originPlace">
              <el-cascader
                v-model="formData.originPlace"
                :options="citys"
                placeholder="请选择产地"
                @change="selectChange('originPlace')"
                :props="{
                  value: 'name',
                  label: 'name',
                  checkStrictly: true,
                }"
              ></el-cascader>
            </el-form-item>
            <el-form-item label="产地详细地址" prop="">
              <el-input
                v-model.trim="formData.originPlaceDetail"
                placeholder="请输入产地详细地址"
                maxlength="100"
              ></el-input>
            </el-form-item>
          </div>
          <div class="line line3">
            <el-form-item label="企业名称" prop="enterpriseName">
              <el-input
                v-model.trim="formData.enterpriseName"
                placeholder="请输入企业名称"
                maxlength="64"
              ></el-input>
            </el-form-item>
            <el-form-item label="联系人" prop="contacts">
              <el-input
                v-model.trim="formData.contacts"
                placeholder="请输入联系人"
                maxlength="20"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="联系方式"
              prop="contactInfo"
              class="contactInfo"
              label-width="90px"
            >
              <el-input
                v-model.trim="formData.contactInfo"
                placeholder="请输入联系方式"
                maxlength="11"
                @input="formatData('contactInfo')"
              ></el-input>
            </el-form-item>
          </div>
          <div class="textarea-box">
            <el-form-item label="竞价须知" prop="biddingNotice">
              <el-input
                type="textarea"
                v-model="formData.biddingNotice"
                :rows="3"
                maxlength="5000"
                placeholder="请输入竞价须知"
              ></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="title">竞价设置</div>
        <div class="group">
          <div class="line line3">
            <el-form-item label="开始时间" prop="biddingStartTime">
              <el-date-picker
                v-model="formData.biddingStartTime"
                type="datetime"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="请选择竞价开始时间"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="结束时间" prop="biddingEndTime">
              <el-date-picker
                v-model="formData.biddingEndTime"
                type="datetime"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="请选择竞价结束时间"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item
              :label="
                $route.query.selectOrderType == 1 ? '最低加价' : '最低降价'
              "
              prop="lowestDiffPrice"
              class="contactInfo"
              label-width="90px"
            >
              <el-input
                v-model="formData.lowestDiffPrice"
                :placeholder="
                  $route.query.selectOrderType == 2 &&
                  $route.query.selectTradeType == 2
                    ? '报价最小降价数值，正整数'
                    : '报价最小加价数值，正整数'
                "
                maxlength="3"
                @input="formatData('lowestDiffPrice')"
              ></el-input>
            </el-form-item>
          </div>
          <!-- <div class="line">
            <el-form-item label="交割开始时间" prop="deliveryStartTime">
              <el-date-picker
                v-model="formData.deliveryStartTime"
                type="datetime"
                placeholder="请选择交割开始时间"
                value-format="yyyy-MM-dd HH:mm:ss"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="交割结束时间" prop="deliveryEndTime">
              <el-date-picker
                v-model="formData.deliveryEndTime"
                type="datetime"
                placeholder="请选择交割结束时间"
                value-format="yyyy-MM-dd HH:mm:ss"
              >
              </el-date-picker>
            </el-form-item>
          </div> -->
          <div class="checke-box">
            <div
              class="checke-item"
              v-for="item in formData.setList"
              :key="item.id"
            >
              <el-checkbox
                v-model="item.checked"
                :true-label="1"
                :false-label="0"
                >{{ item.dictName }}</el-checkbox
              >
              <p class="tip">※{{ item.dictValue || "." }}</p>
            </div>
            <div class="checke-item">
              <h6><i class="hy-icon-icon"></i>自动判定</h6>
              <p class="tip">
                ※系统自动判断最{{
                  $route.query.selectOrderType == 1 ? "高" : "低"
                }}价者中标(多个商品竞价是价格相加报价最{{
                  $route.query.selectOrderType == 1 ? "高" : "低"
                }}者中标),{{
                  $route.query.selectOrderType == 1
                    ? "当最高价相同时,最先报价者中标"
                    : "当最低价相同时,最先报价者中标"
                }}
              </p>
            </div>
          </div>
        </div>
        <div class="btn-wrap">
          <el-button type="primary" @click="submit" class="hy-btn"
            >立即发布</el-button
          >
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { reqDictType, reqAddBiddingGoods } from "@/api/hy";
import citys from "@/assets/json/citys";
import { getTreeV2 } from "@/api/Medium.js";
export default {
  data() {
    const validateContacts = (rule, value, callback) => {
      let validate = /^[\u4e00-\u9fa5]{2,20}$/;
      if (!validate.test(value)) {
        return callback(new Error("名称只能输入2-20位汉字"));
      } else {
        return callback();
      }
    };
    return {
      citys: citys,
      formData: {
        initialCount: "",
        taxIncluded: "",
        priceType: "",
        floorPrice: "",
        bond: "",
        remark: "",
        payType: "",
        deliveryPlace: "",
        deliveryPlaceDetail: "",
        originPlace: "",
        originPlaceDetail: "",
        enterpriseName: "",
        contacts: "",
        deliveryPeriod: "",
        contactInfo: "",
        biddingNotice: "",
        biddingStartTime: "",
        biddingEndTime: "",
        lowestDiffPrice: "",
        partNeedAudit: 0,
        minNumber: "",
        // deliveryStartTime: "",
        // deliveryEndTime: "",
        quotaList: [],
        tradeType: 2,
        orderType: 1,
        goodsType: "",
        setList: [],
        deliveryMode: "", //提货方式
        ifMinNumber: 0,
        goodsCategoryList: [
          {
            goodsName: "", //商品名称
            goodsType: "", //商品类型
            goodsPrice: "", //商品价格
            key: 0, //key
            number: "", //数量
            quotaList: [], //指标列表
            checked: false, //价格是否面议
          },
        ],
      },
      rules: {
        partNeedAudit: [
          {
            required: true,
            message: "请选择商品是否需要审核",
            trigger: "change",
          },
        ],
        ifMinNumber: [
          {
            required: true,
            message: "请选择是否配置最低参与人数",
            trigger: "change",
          },
        ],
        minNumber: [
          { required: true, message: "请输入最低参与人数", trigger: "blur" },
        ],
        deliveryPeriod: [
          { required: true, message: "请输入交货期限", trigger: "blur" },
        ],
        goodsName: [
          { required: true, message: "请输入商品名称", trigger: "change" },
        ],
        goodsType: [
          { required: true, message: "请选择商品类别", trigger: "blur" },
        ],
        initialCount: [
          { required: true, message: "请输入总量", trigger: "blur" },
        ],
        taxIncluded: [
          { required: true, message: "请选择价格类型", trigger: "blur" },
        ],
        priceType: [
          { required: true, message: "请选择价格类型", trigger: "blur" },
        ],
        payType: [
          { required: true, message: "请选择支付方式", trigger: "blur" },
        ],
        deliveryMode: [
          { required: true, message: "请选择配送方式", trigger: "blur" },
        ],
        floorPrice: [
          { required: true, message: "请输入竞价底价", trigger: "blur" },
        ],
        bond: [{ required: true, message: "请输入保证金", trigger: "blur" }],
        deliveryPlace: [
          { required: true, message: "请选择交割地", trigger: "blur" },
        ],
        deliveryPlaceDetail: [
          { required: true, message: "请输入交割地详细地址", trigger: "blur" },
        ],
        originPlace: [
          { required: true, message: "请选择产地", trigger: "blur" },
        ],
        originPlaceDetail: [
          { required: true, message: "请输入产地详细地址", trigger: "blur" },
        ],
        enterpriseName: [
          { required: true, message: "请输入企业名称", trigger: "blur" },
        ],
        contacts: [
          { required: true, message: "请输入联系人", trigger: "blur" },
          { validator: validateContacts, trigger: "blur" },
        ],
        contactInfo: [
          { required: true, message: "请输入联系方式", trigger: "blur" },
        ],
        biddingStartTime: [
          { required: true, message: "请选择开始日期", trigger: "blur" },
        ],
        biddingEndTime: [
          { required: true, message: "请选择结束日期", trigger: "blur" },
        ],
        lowestDiffPrice: [
          { required: true, message: "请输入最低加价", trigger: "blur" },
        ],
        // deliveryStartTime: [
        //   { required: true, message: "请选择交割开始日期", trigger: "blur" },
        // ],
        // deliveryEndTime: [
        //   { required: true, message: "请选择交割结束日期", trigger: "blur" },
        // ],
        // deliveryPlaceDetail:[
        //   { required: true, message: "请输入交割地详细地址", trigger: "blur" },
        // ],
        // originPlaceDetail: [
        //   { required: true, message: "请输入产地地详细地址", trigger: "blur" },
        // ]
      },
      goodsNameList: [],
      normList: [],
      priceTypeList: [
        { value: 1, label: "坑口价" },
        { value: 2, label: "出厂价" },
        { value: 3, label: "船板价" },
        { value: 4, label: "平仓价" },
        { value: 5, label: "车板价" },
        { value: 6, label: "到厂价" },
      ],
      payType: [
        { value: 1, label: "现金" },
        { value: 2, label: "承兑" },
        { value: 3, label: "现金+承兑" },
      ],
      deliveryMode: [
        { value: 1, label: "送货" },
        { value: 2, label: "自提" },
      ],
      nameList: [],
      dictList: [],
      checked: "",
    };
  },
  created() {
    // this.formData.contacts = window.localStorage.getItem("userName");
    this.formData.enterpriseName = localStorage.getItem("userName");
    this.formData.contactInfo = window.localStorage.getItem("phone");
  },
  mounted() {
    // this.citys = citys.filter(item => item.value == '650100')
    this.getTree();
    let routeQuery = this.$route.query;
    this.formData.orderType = routeQuery.selectOrderType;
    this.getGoodsName();
    this.getSetList();
  },
  methods: {
    handleIfMinNumber() {
      console.log(this.formData.ifMinNumber);
      if (this.formData.ifMinNumber == 0) {
        this.formData.minNumber = "";
      }
    },
    delGoods(goods) {
      this.formData.goodsCategoryList = this.formData.goodsCategoryList.filter(
        (item) => item.key != goods.key
      );
    },
    addGoodes() {
      if (this.formData.goodsCategoryList.length >= 5) {
        this.$message.error("数量超出限制");
        return;
      }
      this.formData.goodsCategoryList.push({
        goodsType: "",
        goodsName: "",
        goodsPrice: "",
        number: "", //数量
        key:
          this.formData.goodsCategoryList[
            this.formData.goodsCategoryList.length - 1
          ].key + 1,
        quotaList: [],
        checked: false,
      });
      console.log(this.formData.goodsCategoryList);
    },
    getTree() {
      getTreeV2().then((res) => {
        if (res.code == 0) {
          this.citys = this.deleteChildren(res.data);
        }
      });
    },
    deleteChildren(arr) {
      let childs = arr;
      for (let i = childs.length; i--; i > 0) {
        if (childs[i].children) {
          if (childs[i].children.length) {
            this.deleteChildren(childs[i].children);
          } else {
            delete childs[i].children;
          }
        }
      }
      return arr;
    },
    formatFloat(goodsIndex, index, name) {
      this.formData.goodsCategoryList[goodsIndex].quotaList[index][name] =
        this.formData.goodsCategoryList[goodsIndex].quotaList[index][
          name
        ].match(/^\d*(\.?\d{0,23})/g)[0] || "";
      if (
        this.formData.goodsCategoryList[goodsIndex].quotaList[index][
          name
        ].indexOf(".") > 0
      ) {
        this.formData.goodsCategoryList[goodsIndex].quotaList[index][name] =
          this.formData.goodsCategoryList[goodsIndex].quotaList[index][
            name
          ].substring(
            0,
            this.formData.goodsCategoryList[goodsIndex].quotaList[index][
              name
            ].indexOf(".") + 3
          );
      } else {
        this.formData.goodsCategoryList[goodsIndex].quotaList[index][name] =
          this.formData.goodsCategoryList[goodsIndex].quotaList[index][
            name
          ].substring(0, 20);
      }
    },
    selectChange(name) {
      this.$refs.form.clearValidate(name);
    },
    getSetList() {
      reqDictType({ dictType: "bidding_set" })
        .then((ret) => {
          if (ret.code == 0) {
            this.formData.setList = ret.data.children;
            console.log(this.setList, "-----this.setList----");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    goodsChange(e, obj, index) {
      outer: for (let i = 0; i < this.dictList.length; i++) {
        let item = this.dictList[i];
        // if (item.id == e) {
        //   item.children.forEach((items) => {
        //     this.$set(items, "quotaValue", "");
        //   });
        //   obj.dictName = item.dictName
        //   this.goodsName = item.dictName;
        //   obj.quotaList = item.children.map((item) => {
        //     let arr = item.dictValue.split(";");
        //     return {
        //       ...item,
        //       dictName: item.dictName + "(" + arr[0] + ")" + arr[2],
        //       dictValue: arr[1],
        //     };
        //   });
        //   console.log(this.formData.quotaList);
        //   break outer;
        // }
        if (item.id == e) {
          item.children.forEach((items) => {
            this.$set(items, "quotaValue", "");
          });
          console.log(item);
          obj.dictName = item.dictName;
          this.goodsName = item.dictName;
          obj.quotaList = item.children.map((item) => {
            let arr = item.dictValue.split(";");
            return {
              ...item,
              dictName: item.dictName + "(" + arr[0] + ")" + arr[2],
              dictValue: arr[1],
            };
          });
          break outer;
        }
      }
      if (e.length > 0) {
        this.$refs.form.clearValidate(
          "goodsInfo.goodsCategoryList." + index + ".goodsName"
        );
      }
    },
    vaildZero(name) {
      if (this.formData[name] == 0) {
        this.formData[name] = "";
      }
    },
    formatData(name) {
      if (name == "bond") {
        this.formData[name] =
          this.formData[name].match(/^\d*(\.?\d{0,2})/g)[0] || "";
        if (this.formData[name].indexOf(".") > 0) {
          this.formData[name] =
            this.formData[name].split(".")[0].slice(0, 3) +
              "." +
              this.formData[name].split(".")[1] || "";
        } else {
          this.formData[name] =
            this.formData[name].match(/^\d*/g)[0].slice(0, 3) || "";
        }
      } else {
        this.formData[name] = this.formData[name].match(/^\d*/g)[0] || "";
        if (this.formData[name] == 0) {
          this.formData[name] = "";
        }
      }
    },
    formatDatas(index, key) {
      this.formData["goodsCategoryList"][index][key] =
        this.formData["goodsCategoryList"][index][key].match(/^\d*/g)[0] || "";
      if (this.formData["goodsCategoryList"][index][key] == 0) {
        this.formData["goodsCategoryList"][index][key] = "";
      }
    },
    getGoodsName() {
      reqDictType({ dictType: "coal_type" })
        .then((ret) => {
          if (ret.code == 0) {
            this.dictList = JSON.parse(JSON.stringify(ret.data.children));
            let data = ret.data.children;
            for (let i = 0; i < data.length; i++) {
              let item = data[i];
              for (let j = 0; j < item.children.length; j++) {
                let sub = item.children[j];
                delete sub.children;
              }
            }
            this.nameList = data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          // if(this.formData.initialCount==0||this.formData.floorPrice==0||this.formData.bond==0) return this.$message.error('产品总量,竞价底价,保证金都不能为零')
          this.submitFn();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    initSubmitData() {
      let data = {
        ...this.formData,
      };
      // data.goodsType = data.goodsName;
      data.deliveryPlace = data.deliveryPlace.join(",");
      data.originPlace = data.originPlace.join(",");
      // data.goodsName = this.goodsName;
      data.goodsCategoryList.forEach((item) => {
        item.quotaList = item.quotaList.map((quota) => {
          return {
            quotaId: quota.id || quota.quotaId,
            quotaValue: quota.quotaValue,
          };
        });
      });
      data.goodsCategoryList = data.goodsCategoryList.map((item) => {
        return {
          goodsName: item.goodsName,
          goodsPrice: item.goodsPrice,
          goodsType: item.goodsType,
          quotaList: item.quotaList,
          number: item.number,
        };
      });
      data.setList = data.setList.map((item) => {
        return {
          setId: item.id,
          setValue: !item.checked ? 0 : 1,
        };
      });
      // data.bond = data.bond * 10000;
      return data;
    },
    submitFn() {
      if (this.submiting) return;
      this.submiting = true;
      let data = this.initSubmitData();
      console.log(data);
      reqAddBiddingGoods(data)
        .then((ret) => {
          if (ret.code == 0) {
            this.$confirm("发布成功,请等待平台审核", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "关闭",
              cancelButtonClass: "hy-confirm-cancel-btn",
              type: "warning",
              customClass: "hy-confirm",
              iconClass: "hy-icon-jingjia",
            })
              .then(() => {
                this.$router.go(-1);
              })
              .catch(() => {
                this.$router.go(-1);
              });
          } else {
            this.$message({
              type: "error",
              message: ret.message,
            });
          }
          this.submiting = false;
        })
        .catch((err) => {
          this.submiting = false;
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.add-goods {
  width: 1195px !important;
  margin: 14px auto !important;
  background: white !important;
  border: 1px solid #e5e5e5 !important;
  border-top: none !important;

  &::before,
  &::after {
    display: table !important;
    content: "" !important;
  }
}

.title {
  font-size: 18px !important;
  font-weight: bold !important;
  font-family: PingFang SC !important;
  padding: 14px 15px !important;
  border: 1px solid #e5e5e5 !important;
  border-left: none !important;
  border-right: none !important;
  background: #f7f7f7 !important;
  display: flex !important;
  align-items: center !important;
}

.btn-wrap {
  text-align: center !important;
  margin-bottom: 35px !important;

  .hy-btn {
    width: 193px !important;
  }
}

.floorPrice,
.bond {
  .el-input {
    width: 276px !important;
  }
}

.listingPrice .el-checkbox {
  margin-left: 20px !important;
}

.textarea-box {
  width: 100% !important;
}

.quotaList-box {
  h3 {
    font-size: 14px !important;
    color: #606266 !important;
    line-height: 40px !important;
    font-weight: normal !important;
    width: 140px !important;
    text-align: right !important;
    padding-right: 12px !important;
  }

  .list {
    display: flex !important;
    align-items: center !important;
    flex-wrap: wrap !important;

    .quota-item {
      width: 50% !important;
      flex-shrink: 0 !important;
    }
  }
}

.group {
  padding: 32px 39px !important;
  background: white !important;
}

.line {
  display: flex !important;
  align-items: flex-start !important;

  .el-input,
  .el-select,
  .el-cascader {
    width: 275px !important;
  }
}
.normal {
  ::v-deep .el-form-item__label {
    line-height: normal !important;
  }
}
.goods-list {
  flex-wrap: wrap;
  background-color: #f5f5f5;
  padding-top: 20px;
  margin-top: 10px;
  &:nth-child(1) {
    margin-top: 0;
  }
  .el-input,
  .el-select,
  .el-cascader {
    width: 413px !important;
  }
  .number {
    width: 333px !important;
  }
}
.col2 {
  .radio-group,
  .el-input,
  .el-select,
  .el-cascader {
    width: 413px !important;
  }
}

.adress {
  .el-form-item:nth-child(2) {
    .el-input {
      width: 564px !important;
    }
  }
}

.line3 .contactInfo {
  .el-input {
    width: 200px !important;
  }
}

.pirceType {
  display: flex !important;
  align-items: center !important;

  .el-select,
  .el-input {
    width: 137.5px !important;
  }
}

.checke-box {
  display: flex !important;
  // align-items: center !important;
  flex-wrap: wrap !important;
  justify-content: space-between !important;
  padding: 0 35px !important;

  .checke-item {
    width: 494px !important;
    border-bottom: 1px dashed #e5e5e5 !important;
    padding-bottom: 10px !important;
    margin-bottom: 20px !important;

    &::v-deep .el-checkbox__label {
      color: #2079fb;
      font-family: FZLanTingHei-R-GBK;
    }

    h6 {
      color: #2079fb;
      font-weight: normal;
      font-family: FZLanTingHei-R-GBK;

      i {
        color: #2079fb;
        margin-right: 10px;
      }
    }

    p {
      line-height: 30px !important;
    }

    .tip {
      color: #fb4d4d;
      font-family: FZLanTingHei-R-GBK;
      font-size: 12px;
      padding-left: 25px;
    }
  }
}
</style>
