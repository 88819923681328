<template>
  <div>
    <div class="add-goods">
      <el-form ref="form" :model="formData" :rules="rules" label-width="140px">
        <div class="title">基本信息</div>
        <div class="group info-box">
          <div class="line">
            <el-form-item label="线路名称" prop="lineName">
              <el-input
                v-model="formData.lineName"
                placeholder="请输入线路名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="线路类型" prop="lineType">
              <el-select
                v-model="formData.lineType"
                placeholder="请选择线路类型"
              >
                <el-option
                  v-for="item in nameList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="line">
            <el-form-item label="货物名称" prop="goodsName">
              <el-input
                v-model.trim="formData.goodsName"
                placeholder="请输入货物名称"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="发运量(吨)" prop="monthFreightVolume">
              <el-input
                v-model.trim="formData.monthFreightVolume"
                @input="formatInput('monthFreightVolume')"
                @blur="formatInput1('monthFreightVolume')"
                placeholder="请输入发运量"
              >
              </el-input>
            </el-form-item>
          </div>
          <div class="line">
            <el-form-item label="是否含税" prop="taxIncluded">
              <el-select
                v-model="formData.taxIncluded"
                placeholder="是否含税"
                @change="selectChange('taxIncluded')"
              >
                <el-option label="含税" :value="0"></el-option>
                <el-option label="不含税" :value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="竞价限价(元/吨)" prop="floorPrice">
              <el-input
                v-model.trim="formData.floorPrice"
                @input="formatInput('floorPrice')"
                @blur="formatInput1('floorPrice')"
                placeholder="请输入竞价限价"
              >
              </el-input>
            </el-form-item>
          </div>
          <div class="line">
            <el-form-item label="保证金(万元)" prop="bond">
              <el-input
                v-model.trim="formData.bond"
                @input="formatInput('bond')"
                @blur="formatInput1('bond')"
                placeholder="请输入保证金金额"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="服务期限" prop="val">
              <el-date-picker
                v-model="formData.val"
                value-format="yyyy-MM-dd"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="valChange"
              >
              </el-date-picker>
            </el-form-item>
          </div>
          <div class="line">
            <el-form-item label="支付方式" prop="payType">
              <el-select
                v-model="formData.payType"
                placeholder="支付方式"
                @change="selectChange('payType')"
              >
                <el-option label="现汇" :value="1"></el-option>
                <el-option label="承兑" :value="2"></el-option>
                <el-option label="现汇+承兑" :value="3"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="运距(公里)" prop="transportDistance">
              <el-input
                v-model.trim="formData.transportDistance"
                @input="formatInput('transportDistance')"
                @blur="formatInput1('transportDistance')"
                placeholder="请输入运输距离"
              >
              </el-input>
            </el-form-item>
          </div>
          <div class="line">
            <el-form-item label="出发地" prop="originPlace">
              <el-cascader
                v-model="formData.originPlace"
                @change="selectChange('originPlace')"
                :options="citys"
                placeholder="请选择出发地"
                :props="{
                  value: 'name',
                  label: 'name',
                  checkStrictly: true,
                }"
              ></el-cascader>
            </el-form-item>
            <el-form-item label="出发地详细地址" prop="originPlaceDetail">
              <el-input
                v-model.trim="formData.originPlaceDetail"
                placeholder="请输入详细地址"
              >
              </el-input>
            </el-form-item>
          </div>
          <div class="line">
            <el-form-item label="目的地" prop="deliveryPlace">
              <el-cascader
                v-model="formData.deliveryPlace"
                @change="selectChange('deliveryPlace')"
                :options="citys"
                placeholder="请选择目的地"
                :props="{
                  value: 'name',
                  label: 'name',
                  checkStrictly: true,
                }"
              ></el-cascader>
            </el-form-item>
            <el-form-item label="目的地详细地址" prop="deliveryPlaceDetail">
              <el-input
                v-model.trim="formData.deliveryPlaceDetail"
                placeholder="请输入详细地址"
              >
              </el-input>
            </el-form-item>
          </div>
          <div class="line">
            <el-form-item label="允许综合途耗(‰)" prop="allowloss">
              <el-input-number
                placeholder="请输入允许综合途耗"
                v-model="formData.allowloss"
                :min="0"
                :controls="false"
              ></el-input-number>
            </el-form-item>
          </div>

          <!-- <div class="quotaList-box" v-if="formData.quotaList.length > 0">
            <h3>商品指标</h3>
            <div class="list">
              <div
                class="quota-item"
                v-for="(item, index) in formData.quotaList"
                :key="item.id"
              >
                <el-form-item
                  :label="item.dictName"
                  :prop="'quotaList.' + index + '.quotaValue'"
                  :rules="{
                    required: true,
                    message: item.dictName + '不能为空',
                    trigger: 'blur',
                  }"
                >
                  <el-input
                    v-model="item.quotaValue"
                    :placeholder="`请输入${item.dictName}`"
                    @input="formatFloat(index, 'quotaValue')"
                  >
                    <template slot="append" v-if="item.dictValue">{{
                      item.dictValue
                    }}</template>
                  </el-input>
                </el-form-item>
              </div>
            </div>
          </div> -->
          <div class="textarea-box">
            <el-form-item label="备注说明" prop="">
              <el-input
                type="textarea"
                v-model="formData.remark"
                :rows="3"
                maxlength="100"
                placeholder="请输入备注"
                show-word-limit
              ></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="title">其他信息</div>
        <div class="group">
          <div class="line line3">
            <el-form-item label="企业名称" prop="enterpriseName">
              <el-input
                v-model.trim="formData.enterpriseName"
                placeholder="请输入企业名称"
                maxlength="64"
              ></el-input>
            </el-form-item>
            <el-form-item label="联系人" prop="contacts">
              <el-input
                v-model.trim="formData.contacts"
                placeholder="请输入联系人"
                maxlength="20"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="联系方式"
              prop="contactInfo"
              class="contactInfo"
              label-width="90px"
            >
              <el-input
                v-model.trim="formData.contactInfo"
                placeholder="请输入联系方式"
                maxlength="11"
                @input="formatData('contactInfo')"
              ></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="title">竞价设置</div>
        <div class="group">
          <div class="line">
            <el-form-item label="开始时间" prop="biddingStartTime">
              <el-date-picker
                v-model="formData.biddingStartTime"
                type="datetime"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="请选择竞价开始时间"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="结束时间" prop="biddingEndTime">
              <el-date-picker
                v-model="formData.biddingEndTime"
                type="datetime"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="请选择竞价结束时间"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="最低降价" prop="lowestDiffPrice">
              <el-input
                style="width: 150px !important"
                v-model.trim="formData.lowestDiffPrice"
                @input="formatInput('lowestDiffPrice')"
                @blur="formatInput1('lowestDiffPrice')"
                placeholder="报价最小降价数值"
              ></el-input>
            </el-form-item>
          </div>
          <div class="textarea-box">
            <el-form-item label="竞价须知" prop="biddingNotice">
              <el-input
                type="textarea"
                v-model="formData.biddingNotice"
                :rows="3"
                maxlength="5000"
                placeholder="请输入竞价须知"
              ></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="checke-box">
          <div
            class="checke-item"
            v-for="item in formData.setList"
            :key="item.id"
          >
            <el-checkbox
              v-model="item.checked"
              :true-label="1"
              :false-label="0"
              >{{ item.dictName }}</el-checkbox
            >
            <p class="tip">※{{ item.dictValue || "." }}</p>
          </div>
          <div class="checke-item">
            <h6><i class="hy-icon-icon"></i>自动判定</h6>
            <p class="tip">
              ※系统自动判断最{{
                $route.query.selectOrderType == 1 ? "高" : "低"
              }}价者中标,{{
                $route.query.selectOrderType == 1
                  ? "当最高价相同时,最先报价者中标"
                  : "当最低价相同时,最先报价者中标"
              }}
            </p>
          </div>
        </div>
        <div class="btn-wrap">
          <el-button type="primary" @click="submit" class="hy-btn"
            >立即发布</el-button
          >
        </div>
      </el-form>
    </div>

    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
      <div>
        <el-form ref="forms" :model="priceList" label-width="100px">
          <el-form-item label="数量">
            <el-input v-model="priceList.groupCount"></el-input>
          </el-form-item>
          <el-form-item label="价格(元/吨)" label-width="100px">
            <el-input v-model="priceList.groupPrice"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="add">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { reqDictType, reqAddGroupGoods } from "@/api/hy";
import { addTbGoods } from "@/api/Logistics";
import citys from "@/assets/json/citys";
import _ from "lodash";
import { getTreeV2 } from "@/api/Medium.js";
import { number } from "echarts";
export default {
  data() {
    const validateContacts = (rule, value, callback) => {
      let validate = /^[\u4e00-\u9fa5]{2,20}$/;
      if (!validate.test(value)) {
        return callback(new Error("名称只能输入2-20位汉字"));
      } else {
        return callback();
      }
    };
    return {
      tableData: [],
      priceList: {
        groupCount: "",
        groupPrice: "",
      },
      dialogVisible: false,
      citys: citys,
      formData: {
        val: "",
        goodsName: "",
        lineType: "",
        lineName: "",
        monthFreightVolume: "",
        floorPrice: "",
        bond: "",
        serviceStartTime: "", //	服务期限开始时间
        serviceEndTime: "", //服务期限结束时间
        payType: "", //支付方式
        transportDistance: "", //运距（公里）
        originPlace: "", //出发地
        originPlaceDetail: "", //出发地详细地址
        deliveryPlace: "", //目的地
        deliveryPlaceDetail: "", //目的详细地址
        remark: "", //备注
        enterpriseName: "", //企业名称
        contacts: "", //联系人
        contactInfo: "", //联系方式
        biddingStartTime: "", //竞价开始时间
        biddingEndTime: "", //竞价结束时间
        lowestDiffPrice: "", //最低差价
        biddingNotice: "", //竞价须知
        setList: [],

        // initialCount: "",
        // taxIncluded: "",
        // priceType: "",
        // listingPrice: "",
        // remark: "",
        // payType: "",
        // deliveryPlace: "",
        // deliveryPlaceDetail: "",
        // originPlace: "",
        // originPlaceDetail: "",
        // enterpriseName: "",
        // contacts: "",
        // contactInfo: "",
        // biddingNotice: "",
        // biddingStartTime: "",
        // biddingEndTime: "",
        // quotaList: [],
        // lineName: "",
        // deliveryMode: "", //提货方式
      },
      rules: {
        lineName: [
          { required: true, message: "请输入线路名称", trigger: "blur" },
        ],
        lineType: [
          { required: true, message: "请选择线路类型", trigger: "blur" },
        ],
        goodsName: [
          { required: true, message: "请输入货物名称", trigger: "blur" },
        ],
        monthFreightVolume: [
          { required: true, message: "请输入发运量", trigger: "blur" },
        ],
        floorPrice: [
          { required: true, message: "请输入竞价限价", trigger: "blur" },
        ],
        bond: [{ required: true, message: "请输入保证金", trigger: "blur" }],
        val: [{ required: true, message: "请选择服务期限", trigger: "blur" }],
        transportDistance: [
          { required: true, message: "请选择服务期限", trigger: "blur" },
        ],
        taxIncluded: [
          { required: true, message: "请输入运距", trigger: "blur" },
        ],
        priceType: [
          { required: true, message: "请选择价格类型", trigger: "blur" },
        ],
        payType: [
          { required: true, message: "请选择支付方式", trigger: "blur" },
        ],
        deliveryMode: [
          { required: true, message: "请选择配送方式", trigger: "blur" },
        ],
        listingPrice: [
          { required: true, message: "请输入单吨价格", trigger: "blur" },
        ],
        deliveryPlace: [
          { required: true, message: "请选择目的地", trigger: "blur" },
        ],
        deliveryPlaceDetail: [
          { required: true, message: "请输入目的地详细地址", trigger: "blur" },
        ],
        originPlace: [
          { required: true, message: "请选择出发地", trigger: "blur" },
        ],
        originPlaceDetail: [
          { required: true, message: "请输入出发地详细地址", trigger: "blur" },
        ],
        enterpriseName: [
          { required: true, message: "请输入企业名称", trigger: "blur" },
        ],
        contacts: [
          { required: true, message: "请输入联系人", trigger: "blur" },
          { validator: validateContacts, trigger: "blur" },
        ],
        contactInfo: [
          { required: true, message: "请输入联系方式", trigger: "blur" },
        ],
        biddingStartTime: [
          { required: true, message: "请选择开始日期", trigger: "blur" },
        ],
        biddingEndTime: [
          { required: true, message: "请选择结束日期", trigger: "blur" },
        ],
        allowloss: [
          { required: true, message: "请输入允许综合途耗", trigger: "blur" },
        ],
      },
      goodsNameList: [],
      normList: [],
      priceTypeList: [
        { value: 1, label: "坑口价" },
        { value: 2, label: "出厂价" },
        { value: 3, label: "船板价" },
        { value: 4, label: "平仓价" },
        { value: 5, label: "车板价" },
        { value: 6, label: "到厂价" },
      ],
      payType: [
        { value: 1, label: "现金" },
        { value: 2, label: "承兑" },
        { value: 3, label: "现金+承兑" },
      ],
      deliveryMode: [
        { value: 1, label: "送货" },
        { value: 2, label: "自提" },
      ],
      nameList: [
        { value: 1, label: "短名单模式" },
        { value: 2, label: "公开模式" },
      ],
      dictList: [],
      checked: "",
    };
  },
  created() {
    this.formData.enterpriseName = localStorage.getItem("userName");
    this.formData.contactInfo = window.localStorage.getItem("phone");
  },
  mounted() {
    console.log(this.citys);
    this.getTree();
    let routeQuery = this.$route.query;
    this.getSetList();
  },
  methods: {
    formatInput1(key) {
      if (this.formData[key].indexOf(".") == this.formData[key].length - 1) {
        this.formData[key] = this.formData[key].slice(
          0,
          this.formData[key].length - 1
        );
      }
    },
    formatInput(key, flag) {
      if (key == "lowestDiffPrice") {
        this.formData[key] =
          this.formData[key].match(/\d+\.?\d{0,1}/, "") === null
            ? ""
            : this.formData[key].match(/\d+\.?\d{0,1}/, "")[0];
      } else {
        this.formData[key] =
          this.formData[key].match(/\d+\.?\d{0,2}/, "") === null
            ? ""
            : this.formData[key].match(/\d+\.?\d{0,2}/, "")[0];
      }
    },
    valChange(e) {
      this.formData.serviceStartTime = e[0];
      this.formData.serviceEndTime = e[1];
    },
    newAdd() {
      this.dialogVisible = true;
      this.priceList.groupCount = "";
      this.priceList.groupPrice = "";
    },
    //增加
    add() {
      const obj = _.cloneDeep(this.priceList);
      this.tableData.push(obj);
      this.dialogVisible = false;
    },
    getTree() {
      getTreeV2().then((res) => {
        if (res.code == 0) {
          this.citys = this.deleteChildren(res.data);
        }
      });
    },
    deleteChildren(arr) {
      let childs = arr;
      for (let i = childs.length; i--; i > 0) {
        if (childs[i].children) {
          if (childs[i].children.length) {
            this.deleteChildren(childs[i].children);
          } else {
            delete childs[i].children;
          }
        }
      }
      return arr;
    },
    formatFloat(index, name) {
      console.log(this.formData.quotaList[index][name]);
      this.formData.quotaList[index][name] =
        this.formData.quotaList[index][name].match(/^\d*(\.?\d{0,23})/g)[0] ||
        "";
      if (this.formData.quotaList[index][name].indexOf(".") > 0) {
        this.formData.quotaList[index][name] = this.formData.quotaList[index][
          name
        ].substring(0, this.formData.quotaList[index][name].indexOf(".") + 3);
      } else {
        this.formData.quotaList[index][name] = this.formData.quotaList[index][
          name
        ].substring(0, 20);
      }
      console.log(this.formData.quotaList[index][name]);
    },
    selectChange(name) {
      this.$refs.form.clearValidate(name);
    },
    getSetList() {
      reqDictType({ dictType: "bidding_set" })
        .then((ret) => {
          if (ret.code == 0) {
            this.formData.setList = ret.data.children;
            console.log(this.setList, "-----this.setList----");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // goodsChange(e) {
    //   outer: for (let i = 0; i < this.dictList.length; i++) {
    //     let item = this.dictList[i];
    //     if (item.id == e) {
    //       item.children.forEach((items) => {
    //         this.$set(items, "quotaValue", "");
    //       });
    //       this.goodsName = item.dictName;
    //       this.formData.quotaList = item.children.map((item) => {
    //         let arr = item.dictValue.split(";");
    //         return {
    //           ...item,
    //           dictName: item.dictName + "(" + arr[0] + ")" + arr[2],
    //           dictValue: arr[1],
    //         };
    //       });
    //       console.log(this.formData.quotaList);
    //       break outer;
    //     }
    //   }
    //   if (e.length > 0) {
    //     this.$refs.form.clearValidate("goodsName");
    //   }
    // },
    formatData(name) {
      this.formData[name] = this.formData[name].match(/^\d*/g)[0] || "";
      if (this.formData[name] == 0) {
        this.formData[name] = "";
      }
    },

    submit() {
      // this.formData.priceList = this.tableData;
      this.formData.lowestDiffPrice = Number(this.formData.lowestDiffPrice);
      this.formData.transportDistance = Number(this.formData.transportDistance);
      this.formData.bond = Number(this.formData.bond);
      this.formData.floorPrice = Number(this.formData.floorPrice);
      this.formData.monthFreightVolume = Number(
        this.formData.monthFreightVolume
      );
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submitFn();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    initSubmitData() {
      let data = {
        ...this.formData,
      };
      data.deliveryPlace = data.deliveryPlace.join(",");
      data.originPlace = data.originPlace.join(",");
      // data.quotaList = data.quotaList.map((item) => {
      //   return {
      //     quotaId: item.id,
      //     quotaValue: item.quotaValue,
      //   };
      // });
      data.setList = data.setList.map((item) => {
        return {
          setId: item.id,
          setValue: !item.checked ? 0 : 1,
        };
      });
      return data;
    },
    submitFn() {
      if (this.submiting) return;
      this.submiting = true;
      let data = this.initSubmitData();
      addTbGoods(data)
        .then((ret) => {
          if (ret.code == 0) {
            this.$confirm("发布成功,请等待平台审核", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "关闭",
              cancelButtonClass: "hy-confirm-cancel-btn",
              type: "warning",
              customClass: "hy-confirm",
              iconClass: "hy-icon-jingjia",
            })
              .then(() => {
                this.$router.go(-1);
              })
              .catch(() => {
                this.$router.go(-1);
              });
          } else {
            this.$message({
              type: "error",
              message: ret.message,
            });
          }
          this.submiting = false;
        })
        .catch((err) => {
          this.submiting = false;
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.add-goods {
  width: 1195px !important;
  margin: 14px auto !important;
  background: white !important;
  border: 1px solid #e5e5e5 !important;
  border-top: none !important;

  &::before,
  &::after {
    display: table !important;
    content: "" !important;
  }
}

.title {
  font-size: 18px !important;
  font-weight: bold !important;
  font-family: PingFang SC !important;
  padding: 14px 15px !important;
  border: 1px solid #e5e5e5 !important;
  border-left: none !important;
  border-right: none !important;
  background: #f7f7f7 !important;
  display: flex !important;
  align-items: center !important;
}

.btn-wrap {
  text-align: center !important;
  margin-bottom: 35px !important;

  .hy-btn {
    width: 193px !important;
  }
}

.floorPrice,
.bond {
  .el-input {
    width: 276px !important;
  }
}

.listingPrice .el-checkbox {
  margin-left: 20px !important;
}

.textarea-box {
  width: 100% !important;
}

.quotaList-box {
  h3 {
    font-size: 14px !important;
    color: #606266 !important;
    line-height: 40px !important;
    font-weight: normal !important;
    width: 140px !important;
    text-align: right !important;
    padding-right: 12px !important;
  }

  .list {
    display: flex !important;
    align-items: center !important;
    flex-wrap: wrap !important;

    .quota-item {
      width: 50% !important;
      flex-shrink: 0 !important;
    }
  }
}

.group {
  padding: 32px 39px !important;
  background: white !important;
}

::v-deep .line {
  display: flex !important;
  align-items: center !important;
  .el-input,
  .el-select,
  .el-cascader {
    width: 275px !important;
  }
  .el-input-number .el-input__inner {
    text-align: left;
  }
}

.adress {
  .el-form-item:nth-child(2) {
    .el-input {
      width: 564px !important;
    }
  }
}

.line3 .contactInfo {
  .el-input {
    width: 200px !important;
  }
}

.pirceType {
  display: flex !important;
  align-items: center !important;

  .el-select,
  .el-input {
    width: 137.5px !important;
  }
}

.checke-box {
  display: flex !important;
  align-items: center !important;
  flex-wrap: wrap !important;
  justify-content: space-between !important;
  padding: 0 35px !important;

  .checke-item {
    width: 494px !important;
    border-bottom: 1px dashed #e5e5e5 !important;
    padding-bottom: 10px !important;
    margin-bottom: 20px !important;

    &::v-deep .el-checkbox__label {
      color: #2079fb;
      font-family: FZLanTingHei-R-GBK;
    }

    h6 {
      color: #2079fb;
      font-weight: normal;
      font-family: FZLanTingHei-R-GBK;

      i {
        color: #2079fb;
        margin-right: 10px;
      }
    }

    p {
      line-height: 30px !important;
    }

    .tip {
      color: #fb4d4d;
      font-family: FZLanTingHei-R-GBK;
      font-size: 12px;
      padding-left: 25px;
    }
  }
}
::v-deep .el-range-separator {
  width: 10%;
}
</style>
